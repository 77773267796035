import { combineReducers } from "@reduxjs/toolkit";

import importExportReducer from "@features/importExport/ImportExportData";
import AuthenticationReducer from "@features/authentication/Authentication";
import MastersReducer from "@features/masters/Masters";
import UIReducer from "@features/ui/UIReducers";
import CredentialProfileReducer from "@features/credentialProfile/CredentialProfileReducers";
import ActivityChecklistReducer from "@features/activityChecklist/ActivityChecklistReducers";
import DepartmentReducer from "@features/departments/Department";
import ManageDocumentsReducer from "@features/manageDocuments/ManageDocumentsReducers";
import ServiceSiteReducer from "@features/serviceSites/ServiceSitesReducer";
import OrganizationReducer from "@features/organizations/OrganizationsReducer";
import ActivityValidatorReducer from "@features/activityValidator/ActivityValidatorReducer";
import DocuSignReducer from "@features/docuSign/DocuSignReducers";
import TrainingReducer from "@features/training/TrainingReducers";
import ExpirablesReducer from "@features/expirables/ExpirablesReducers";
import ManageProfileReducer from "@features/manageProfile/ManageProfileReducer";
import docuSignReportReducer from "@features/reports";
import EmployeeFilesReducer from "@features/employeeFiles/EmployeeFilesReducer";
import ClinicalStaffReducer from "@features/clinicalAndNonClinicalStaff/ClinicalAndNonClinicalStaffReducer";
import SetupIntegrationReducer from "@features/setupIntegration/SetupIntegrationsReducer";
import OrderSummaryReducer from "@features/psv/orderSummary";
import FileTaskReducer from "@features/fileTask/index";
import CommonReducer from "@features/common/index";
import ApplicantsReducer from "@features/req-to-credentialing/index";
import TrackingReportReducer from "@features/tracking-report/index";

const rootReducer = combineReducers({
  importExport: importExportReducer,
  authenticationRecord: AuthenticationReducer,
  masters: MastersReducer,
  ui: UIReducer,
  credentialProfile: CredentialProfileReducer,
  activityChecklist: ActivityChecklistReducer,
  department: DepartmentReducer,
  manageDocuments: ManageDocumentsReducer,
  serviceSite: ServiceSiteReducer,
  organizations: OrganizationReducer,
  activityValidator: ActivityValidatorReducer,
  docuSign: DocuSignReducer,
  training: TrainingReducer,
  expirables: ExpirablesReducer,
  manageProfile: ManageProfileReducer,
  reports: docuSignReportReducer,
  employeeFiles: EmployeeFilesReducer,
  clinicalStaff: ClinicalStaffReducer,
  setupIntegration: SetupIntegrationReducer,
  orderSummary: OrderSummaryReducer,
  fileTask: FileTaskReducer,
  common: CommonReducer,
  applicants: ApplicantsReducer,
  trackingReport: TrackingReportReducer,
});

export default rootReducer;
