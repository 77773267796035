import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IReqToCredModel,
  IReqToCredPaginationModel,
} from "@helpers/interfaces/req-to-credentialing";
import { setDefaultReqToCredData } from "@helpers/configMiddlewares/req-to-credentialing";

const initialState = {
  applicantData: {} as IReqToCredModel,
  applicantList: {} as IReqToCredPaginationModel,
  error: null as any,
  loading: false,
  editorLoading: false,
};

const reqToCredentialingSlice = createSlice({
  name: "applicants",
  initialState,
  reducers: {
    updateApplicantData: (state, action: PayloadAction<IReqToCredModel>) => {
      state.applicantData = action.payload;
    },

    updateApplicantList: (
      state,
      action: PayloadAction<IReqToCredPaginationModel>
    ) => {
      state.applicantList = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    resetEditorState: (state) => {
      state.editorLoading = false;
      state.error = null;
      state.applicantData = setDefaultReqToCredData();
    },
  },
});

export const reqToCredentialingActions = reqToCredentialingSlice.actions;
export default reqToCredentialingSlice.reducer;
