import { ICardDisplayProps } from "@helpers/uiInterfaces/common";
import Card from "react-bootstrap/Card";

const CardDisplay: React.FC<ICardDisplayProps> = (props) => {
  const { title, subtitle, data } = props;
  return (
    <Card>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{subtitle}</Card.Subtitle>
        <Card.Text>{data}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default CardDisplay;
