import {
  ICredTrackingModel,
  ICredTrackOrgLinkModel,
} from "@helpers/interfaces/employeeFiles";
import styles from "./Edit_CredentialingTracking.module.scss";
import {
  setDefaultCredTrackingData,
  setDefaultOrgLinkData,
} from "@helpers/configMiddlewares/employeeFiles";
import {
  getCredTrackingData,
  upsertCredTracking,
} from "@services/employeeFiles/EditCredentialingTracking";
import { useAppSelector } from "@hooks/index";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import FormSelectInputControl from "@common/form-controls/FormSelectInputControl";
import MultiSelectInput from "@common/multi-select/MultiSelect";
import FormDateInputControl from "@common/form-controls/FormDateInputControl";
import FormTextareaInputControl from "@common/form-controls/FormTextareaInputControl";
import moment from "moment";
import {
  getAllNetworkByOrganizationId,
  getAllNetworkServiceSiteByOrganizationId,
  getPropertiesByPropertyType,
} from "@services/master/master.services";
import {
  generateSelectedLanguageCodeJSON,
  getCodeIdsString,
  parseCredJobSelectControlsData,
  parseSelectContactsControlsData,
  parseSelectControlsData,
} from "@utilities/index";
import reqCredTypeOptions from "@constants/json-data/masters-data/reqCredTypeOptions";
import rushReqOptions from "@constants/json-data/masters-data/request-type-options";
import FormInputTextControl from "@common/form-controls/FormInputTextControl";
import { getAllJobCredStatus } from "@services/tracking-report";
import { parseSelectedClientsData } from "@pages/RequestCredentialing/utilities";
import { toastMessageActions } from "@features/ui/ToastMessage";
import messages from "@constants/messages";
import toastTypes from "@constants/toast-types";
import { validateCredentialingTrackingForm } from "../utilities";
import { getOrganizationContactsByType } from "@services/organizations/contact";
import { IEmployee_CredTrackingContentProps } from "@helpers/uiInterfaces/add-edit-employee";
import FormCheckboxControl from "@common/form-controls/FormCheckboxControl";
import propertyTypes from "@constants/json-data/property-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import Spinner from "@common/spinner/Spinner";

const AddEditCredentialingTrackingEditor: React.FC<
  IEmployee_CredTrackingContentProps
> = (props) => {
  const {
    data,
    employeeData,
    jobId,
    setShowEditCredentialingTrackingModel,
    updateSelectedData,
  } = props;

  const dispatch = useDispatch<any>();

  const allNetworkServiceSiteByOrganizationId = useAppSelector(
    (state) => state.masters.allNetworkServiceSiteByOrganizationId
  );
  const clientsData = useAppSelector(
    (state) => state.masters.allNetworkByOrganizationId
  );
  const jobCredStatus = useAppSelector(
    (state) => state.trackingReport.jobCredStatus
  );
  const userRecord = useAppSelector(
    (state) => state.authenticationRecord.userRecord
  );
  const reasonsOfTermination = useAppSelector(
    (state) => state.masters.propertyListByPropertyType
  );
  const credTrackingState = useAppSelector(
    (state) => state.employeeFiles.editCredentialingTracking
  );
  const credTrackingDataList =
    credTrackingState.credentialingTrackingList?.page;

  const [selectedOrganizations, setSelectedOrganizations] = useState<
    Array<any>
  >([]);
  const [credentialerOptions, setCredentialerOptions] = useState([]);
  const [analystOptions, setAnalystOptions] = useState([]);
  const [managerOptions, setManagerOptions] = useState([]);
  const [selectedCredJobStatus, setSelectedCredJobStatus] = useState(0);
  const [sendNotification, setSendNotification] = useState(false);
  const [selectedInstitutions, setSelectedInstitutions] = useState<Array<any>>(
    []
  );
  const [credTrackData, setCredTrackData] = useState<ICredTrackingModel>(
    setDefaultCredTrackingData()
  );
  const [credOrgLinkData, setCredOrgLinkData] = useState<
    Array<ICredTrackOrgLinkModel>
  >([]);
  const [terminationDateErrors, setTerminationDateErrors] = useState<string[]>(
    []
  );
  const [terminationReasonErrors, setTerminationReasonErrors] = useState<
    string[]
  >([]);
  const [showLoader, setShowLoader] = useState(false);

  const formik = useFormik({
    initialValues: credTrackData as ICredTrackingModel,
    validate: validateCredentialingTrackingForm,
    onSubmit: handleSaveControlClick,
  });
  const formikValues = formik.values;
  const formikErrors = formik.errors;

  useEffect(() => {
    loadCredentialingTrackingData();
  }, [data, showLoader]);

  useEffect(() => {
    loadDependencies();
  }, []);

  async function loadDependencies() {
    setShowLoader(true);
    await dispatch(getAllNetworkServiceSiteByOrganizationId());
    await dispatch(getAllJobCredStatus());
    await dispatch(getAllNetworkByOrganizationId(userRecord.organizationId));
    await dispatch(
      getPropertiesByPropertyType(propertyTypes.TERMINATION_REASONS)
    );
    const credentialerRes = await dispatch(getOrganizationContactsByType(9));
    if (credentialerRes.status === 200)
      setCredentialerOptions(credentialerRes.data);

    const analystRes = await dispatch(getOrganizationContactsByType(10));
    if (analystRes.status === 200) setAnalystOptions(analystRes.data);

    const managerRes = await dispatch(getOrganizationContactsByType(11));
    if (managerRes.status === 200) setManagerOptions(managerRes.data);
    setShowLoader(false);
  }

  async function loadCredentialingTrackingData() {
    if (data === null) {
      const _data: ICredTrackingModel = setDefaultCredTrackingData();
      const initialArray = [setDefaultOrgLinkData()];
      const res = await dispatch(
        getAllNetworkByOrganizationId(userRecord.organizationId)
      );
      if (res.status === 200) {
        const defaultOrg = res.data
          .filter((data: any) => data.id === userRecord.organizationId)
          .find((data: any) => ({ label: data.name, value: data.id }));

        if (defaultOrg.id) {
          initialArray[0].organizationId = defaultOrg.id;
          const newDefaultObject = {
            ...setDefaultOrgLinkData(),
          };
          const _updatedCredOrgLinkData = [...initialArray, newDefaultObject];
          setCredOrgLinkData(_updatedCredOrgLinkData);
        } else {
          setCredOrgLinkData(initialArray);
        }
      }
      const updatedData = { ..._data, jobNo: jobId };
      setCredTrackData(updatedData);
      return formik.setValues(updatedData);
    }
    if (!showLoader) {
      const response = await dispatch(
        getCredTrackingData(data.credentialingTrackingId)
      );
      if (response.status === 200) {
        setCredTrackData(response.data);
        setSelectedCredJobStatus(response.data.credJobStatus);
        setSelectedOrganizations(
          parseSelectedClientsData(response.data.credTrackOrgIds, clientsData)
        );
        setSelectedInstitutions(
          generateSelectedLanguageCodeJSON(
            response.data.insituitionRequesting,
            allNetworkServiceSiteByOrganizationId
          )
        );
        const initialArray =
          response.data.credTrackOrganizationLinkList.length > 0
            ? [
                ...response.data.credTrackOrganizationLinkList,
                setDefaultOrgLinkData(),
              ]
            : [setDefaultOrgLinkData()];
        const newInitialArray = initialArray.map((Idata) => ({
          ...Idata,
          credentialingTrackingId: Idata.credentialingTrackingId
            ? Idata.credentialingTrackingId
            : data.credentialingTrackingId,
        }));
        setCredOrgLinkData(newInitialArray);
        formik.setValues(response.data);
      }
    }
  }

  const handleControlChange = (
    index: number,
    field: string,
    value: string | boolean
  ) => {
    setCredOrgLinkData((prevData) => {
      const updatedData: any = [...prevData];
      const previousValue = updatedData[index][field];
      updatedData[index] = {
        ...updatedData[index],
        [field]: value,
      };

      if (field === "isActive" && value === true) {
        updatedData[index] = {
          ...updatedData[index],
          terminateDate: null,
          terminateReason: "0",
        };
      }

      if (field === "organizationId" && value) {
        const exists = updatedData.some(
          (item: any, i: number) => item.organizationId === value && i !== index
        );

        if (exists) {
          dispatch(
            toastMessageActions.showToast({
              message: messages.credTrackingOrgDuplicateMessage,
              type: toastTypes.WARNING,
            })
          );
          if (!previousValue) {
            updatedData[index][field] = "";
          } else {
            updatedData[index][field] = previousValue;
          }
        } else if (index === updatedData.length - 1) {
          updatedData.push({ ...setDefaultOrgLinkData(), organizationId: "" });
        }
      }

      return updatedData;
    });
  };

  const handleRemoveOrgRow = (index: number) => {
    setCredOrgLinkData((prevData) => {
      const updatedData = prevData.filter((_, i) => i !== index);
      return updatedData;
    });
  };

  function handleSelectControlChange(name: string, value: string) {
    if (name === "credJobStatus") {
      if (+value !== 11) {
        formik.setFieldValue("dateReportToClient", null);
      }
      setSelectedCredJobStatus(+value);
      formik.setFieldValue(name, +value);
      return;
    }
    formik.setFieldValue(name, value);
  }

  async function handleSaveControlClick() {
    setTerminationDateErrors(Array(credOrgLinkData.length).fill(""));
    setTerminationReasonErrors(Array(credOrgLinkData.length).fill(""));

    let isValid = true;

    credOrgLinkData.forEach((credOrgData, index) => {
      if (!credOrgData.isActive) {
        if (!credOrgData.terminateDate) {
          setTerminationDateErrors((prev) => {
            const newErrors = [...prev];
            newErrors[index] = "Required";
            return newErrors;
          });
          isValid = false;
        }
        if (!credOrgData.terminateReason) {
          setTerminationReasonErrors((prev) => {
            const newErrors = [...prev];
            newErrors[index] = "Required";
            return newErrors;
          });
          isValid = false;
        }
      }
    });

    if (!isValid) {
      return;
    }

    const values = {
      ...formikValues,
      hrmrUserId: employeeData?.hrmrUserId,
      sendNotification: sendNotification,
      credTrackOrgIds: getCodeIdsString(selectedOrganizations),
      insituitionRequesting: getCodeIdsString(selectedInstitutions),
      credTrackOrgLinkList: credOrgLinkData
        .filter((data) => data.organizationId)
        .map(({ organizationName, isNew, ...data }) => ({
          ...data,
          originalAppointmentDate:
            data.originalAppointmentDate === "0001-01-01T00:00:00"
              ? null
              : data.originalAppointmentDate,
        })),
    };

    delete values.isHide;
    delete values.isDelete;
    delete values.associatedCredentialerName;
    delete values.organizationNames;
    delete values.totalCount;
    delete values.userId;
    delete values.organizationId;
    delete values.assignedManagerName;
    delete values.assignedDataAnalysistName;
    delete values.isCompleted;
    delete values.isEditEnalble;
    delete values.credTrackOrganizationLinkList;
    delete values.isOpenInstance;

    const response = await dispatch(upsertCredTracking(values));
    if (response.status === 200) {
      setShowEditCredentialingTrackingModel(false);
      updateSelectedData(null);
      dispatch(
        toastMessageActions.showToast({
          message: messages.dataSuccessfullySaved,
          type: toastTypes.SUCCESS,
        })
      );
    }
  }

  const renderDynamicFields = () => {
    return (
      <div className={styles.dynamicFieldContainer}>
        {credOrgLinkData.map((credOrgData, index) => {
          const organizationsSelectControlAttributes = {
            label: "Organization(s) - To be Priviledged",
            name: "organizationId",
            disabled: index === 0,
            value: credOrgData.organizationId,
            data: parseSelectControlsData(clientsData),
            onChange(name: string, value: string) {
              handleControlChange(index, "organizationId", value);
            },
          };

          const terminationDateControlAttributes = {
            label: "Termination Date",
            name: "terminateDate",
            disabled: credOrgData.organizationId === "",
            required: true,
            error: credOrgData.isActive ? "" : terminationDateErrors[index],
            value: credOrgData.terminateDate
              ? moment(credOrgData.terminateDate).format("YYYY-MM-DD")
              : "",
            onChange(value: string) {
              handleControlChange(index, "terminateDate", value);
              setTerminationDateErrors((prev) => {
                const newErrors = [...prev];
                newErrors[index] = "";
                return newErrors;
              });
            },
          };

          const reasonOfTerminationControlAttributes = {
            label: "Reason of Termination",
            name: "terminateReason",
            required: true,
            error: credOrgData.isActive ? "" : terminationReasonErrors[index],
            disabled: credOrgData.organizationId === "",
            value: credOrgData.terminateReason,
            data: parseSelectControlsData(reasonsOfTermination),
            onChange(name: string, value: string) {
              handleControlChange(index, "terminateReason", value);
              setTerminationReasonErrors((prev) => {
                const newErrors = [...prev];
                newErrors[index] = "";
                return newErrors;
              });
            },
          };

          const isActiveControlAttributes = {
            label: "Active",
            name: "isActive",
            checked: credOrgData.isActive,
            disabled: credOrgData.organizationId === "",
            onChange(event: React.ChangeEvent<HTMLInputElement>) {
              handleControlChange(index, "isActive", event.target.checked);
            },
          };

          const crossIconAttributes = {
            className: styles.crossIcon,
            icon: faCircleXmark,
            onClick() {
              handleRemoveOrgRow(index);
            },
          };

          return (
            <div key={index} className="row align-items-end">
              <div className="col-md-3 col-sm-12 col-xs-12">
                <FormSelectInputControl
                  {...organizationsSelectControlAttributes}
                />
              </div>
              {!credOrgData.isActive && (
                <>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <FormDateInputControl
                      {...terminationDateControlAttributes}
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <FormSelectInputControl
                      {...reasonOfTerminationControlAttributes}
                    />
                  </div>
                </>
              )}
              <div className="col-md-1 col-sm-12 col-xs-12 checkboxHeight">
                <FormCheckboxControl {...isActiveControlAttributes} />
              </div>
              {index !== 0 && credOrgData.isNew && (
                <div
                  className={`col-md-1 col-sm-12 col-xs-12 ${styles.iconContainer}`}
                >
                  <FontAwesomeIcon {...crossIconAttributes} />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderContent = () => {
    if ((data !== null && credTrackingState.editorLoading) || showLoader) {
      return <Spinner />;
    }

    let associatedCredentialerError = "" as string | undefined;

    if (
      formikErrors.associatedCredentialer !== "" &&
      formik.touched.associatedCredentialer === true
    ) {
      associatedCredentialerError = formikErrors.associatedCredentialer;
    }

    let requestCredTypeError = "" as string | undefined;

    if (
      formikErrors.requestCredType !== "" &&
      formik.touched.requestCredType === true
    ) {
      requestCredTypeError = formikErrors.requestCredType;
    }

    let processingTypeError = "" as string | undefined;

    if (
      formikErrors.processingType !== "" &&
      formik.touched.processingType === true
    ) {
      processingTypeError = formikErrors.processingType;
    }

    let credJobStatusError = "" as string | undefined;

    if (
      formikErrors.credJobStatus !== "" &&
      formik.touched.credJobStatus === true
    ) {
      credJobStatusError = formikErrors.credJobStatus;
    }

    let dateReportedToClientError = "" as string | undefined;

    if (
      formikErrors.dateReportToClient !== "" &&
      formik.touched.dateReportToClient === true
    ) {
      dateReportedToClientError = formikErrors.dateReportToClient;
    }

    let insituitionRequesting: string | undefined = "";

    if (
      formikErrors.insituitionRequesting !== "" &&
      formik.touched.insituitionRequesting === true
    ) {
      insituitionRequesting = formikErrors.insituitionRequesting;
    }

    let workflowStartDateError: string | undefined = "";

    if (
      formikErrors.workflowStartDate !== "" &&
      formik.touched.workflowStartDate === true
    ) {
      workflowStartDateError = formikErrors.workflowStartDate;
    }

    let workflowEndDateError: string | undefined = "";

    if (
      formikErrors.workflowEndDate !== "" &&
      formik.touched.workflowEndDate === true
    ) {
      workflowEndDateError = formikErrors.workflowEndDate;
    }

    const jobNoControlAttributes = {
      label: "Instance ID",
      type: "text",
      name: "jobNo",
      disabled: true,
      value: formikValues.jobNo,
      onChange: formik.handleChange,
    };

    const associatedCredentialerControlAttributes = {
      label: "Associated Credentialer",
      name: "associatedCredentialer",
      required: true,
      error: associatedCredentialerError,
      value: formikValues.associatedCredentialer,
      data: parseSelectContactsControlsData(credentialerOptions),
      onChange: handleSelectControlChange,
      onBlur: formik.handleBlur,
    };

    const assignedDataAnalystControlAttributes = {
      label: "Assigned Data Analyst",
      name: "assignedDataAnalysist",
      value: formikValues.assignedDataAnalysist,
      data: parseSelectContactsControlsData(analystOptions),
      onChange: handleSelectControlChange,
    };

    const assignedManagerControlAttributes = {
      label: "Assigned Manager",
      name: "assignedManager",
      value: formikValues.assignedManager,
      data: parseSelectContactsControlsData(managerOptions),
      onChange: handleSelectControlChange,
    };

    const requestCredTypeControlAttributes = {
      label: "Request Cred Type",
      name: "requestCredType",
      required: true,
      error: requestCredTypeError,
      value: formikValues.requestCredType,
      data: reqCredTypeOptions,
      onChange: handleSelectControlChange,
      onBlur: formik.handleBlur,
    };

    const processingTypeControlAttributes = {
      label: "Processing Type",
      name: "processingType",
      required: true,
      error: processingTypeError,
      value: formikValues.processingType,
      data: rushReqOptions,
      onChange: handleSelectControlChange,
      onBlur: formik.handleBlur,
    };

    const credJobStatusControlAttributes = {
      label: "Cred/Instance Status",
      name: "credJobStatus",
      required: true,
      error: credJobStatusError,
      disabled:
        credTrackingDataList.filter((data) => data.isOpenInstance === true)
          .length > 0
          ? formikValues.isCompleted
            ? true
            : false
          : false,
      value: formikValues.credJobStatus,
      data: parseCredJobSelectControlsData(jobCredStatus),
      onChange: handleSelectControlChange,
      onBlur: formik.handleBlur,
    };

    const dateReportedToClientControlAttributes = {
      label: "Date Reported To Client",
      name: "dateReportToClient",
      required: selectedCredJobStatus === 11,
      error: dateReportedToClientError,
      value: formikValues.dateReportToClient
        ? moment(formikValues.dateReportToClient).format("YYYY-MM-DD")
        : "",
      onChange(value: string) {
        formik.setFieldValue("dateReportToClient", value);
      },
      onBlur: formik.handleBlur,
    };

    const dobControlAttributes = {
      label: "DOB",
      name: "dob",
      value: formikValues.dob
        ? moment(formikValues.dob).format("YYYY-MM-DD")
        : "",
      onChange(value: string) {
        formik.setFieldValue("dob", value);
      },
    };

    const institutionRequestingThisControlAttributes = {
      data: parseSelectControlsData(allNetworkServiceSiteByOrganizationId),
      selectedValues: selectedInstitutions,
      error: insituitionRequesting,
      onChange(data: any) {
        setSelectedInstitutions(data);
        formik.setFieldValue("insituitionRequesting", getCodeIdsString(data));
      },
      onBlur: formik.handleBlur,
    };

    const workflowStartDateControlAttributes = {
      label: "Credentialing From Date",
      name: "workflowStartDate",
      required: formikValues.credJobStatus === 11 ? true : false,
      error: workflowStartDateError,
      value: formikValues.workflowStartDate
        ? moment(formikValues.workflowStartDate).format("YYYY-MM-DD")
        : "",
      onChange(value: string) {
        formik.setFieldValue("workflowStartDate", value);
      },
      onBlur: formik.handleBlur,
    };

    const workflowEndDateControlAttributes = {
      label: "Credentialing To Date",
      name: "workflowEndDate",
      required: formikValues.credJobStatus === 11 ? true : false,
      error: workflowEndDateError,
      value: formikValues.workflowEndDate
        ? moment(formikValues.workflowEndDate).format("YYYY-MM-DD")
        : "",
      onChange(value: string) {
        formik.setFieldValue("workflowEndDate", value);
      },
      onBlur: formik.handleBlur,
    };

    const commentControlAttributes = {
      label: "Comment",
      name: "comment",
      value: formikValues.comment,
      onChange: formik.handleChange,
    };

    // const requestedOrgNameControlAttributes = {
    //   label: "Requested Organization Name",
    //   type: "text",
    //   name: "orgRequestOrgId",
    //   value: formikValues.orgRequestOrgId,
    //   onChange: formik.handleChange,
    // };

    // const requestedUsernameControlAttributes = {
    //   label: "Requested Username",
    //   type: "text",
    //   name: "orgRequestOrgId",
    //   value: formikValues.orgRequestOrgId,
    //   onChange: formik.handleChange,
    // };

    const backButtonControlAttributes = {
      className: `btn btnorg w100`,
      onClick() {
        setShowEditCredentialingTrackingModel(false);
        updateSelectedData(null);
      },
    };

    const saveButtonControlAttributes = {
      className: `btn btnorg w100`,
      onClick() {
        setSendNotification(false);
        formik.handleSubmit();
      },
    };

    const sendNotificationControlAttributes = {
      className: `btn btnorg w150`,
      onClick() {
        setSendNotification(true);
        formik.handleSubmit();
      },
    };

    return (
      <div>
        <div className="row align-items-end mt-5">
          <div className="col-md-3 col-sm-12 col-xs-12">
            <FormInputTextControl {...jobNoControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <FormSelectInputControl
              {...associatedCredentialerControlAttributes}
            />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <FormSelectInputControl {...assignedDataAnalystControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <FormSelectInputControl {...assignedManagerControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <FormSelectInputControl {...requestCredTypeControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <FormSelectInputControl {...processingTypeControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <FormSelectInputControl {...credJobStatusControlAttributes} />
          </div>
          {selectedCredJobStatus === 11 && (
            <div className="col-md-3 col-sm-12 col-xs-12">
              <FormDateInputControl
                {...dateReportedToClientControlAttributes}
              />
            </div>
          )}
          <div className="col-md-3 col-sm-12 col-xs-12">
            <FormDateInputControl {...dobControlAttributes} />
          </div>
          {renderDynamicFields()}
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="form-group">
              <div className="lbl">
                <FontAwesomeIcon
                  icon={faAsterisk}
                  className={`required-asterisk mb-1`}
                />
                <label>Affiliated Institutions</label>
              </div>
              <MultiSelectInput
                {...institutionRequestingThisControlAttributes}
              />
            </div>
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <FormDateInputControl {...workflowStartDateControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <FormDateInputControl {...workflowEndDateControlAttributes} />
          </div>
          {/* <div className="col-md-3 col-sm-12 col-xs-12">
            <FormInputTextControl {...requestedOrgNameControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <FormInputTextControl {...requestedUsernameControlAttributes} />
          </div> */}
          <div className="col-md-3 col-sm-12 col-xs-12">
            <FormTextareaInputControl {...commentControlAttributes} />
          </div>
        </div>
        <div className={styles.btnContainer}>
          <button {...saveButtonControlAttributes}>Save</button>
          <button {...sendNotificationControlAttributes}>
            Save & Send Notification
          </button>
          <button {...backButtonControlAttributes}>Back</button>
        </div>
      </div>
    );
  };

  return (
    <div id={styles.addEditCredentialingTrackingMain}>{renderContent()}</div>
  );
};

export default AddEditCredentialingTrackingEditor;
