import React, { useEffect, useState } from "react";

import { Offcanvas } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { useAppSelector } from "@hooks/index";

import statusTypes from "@constants/json-data/status-types";

import { setDefaultDashboardInitialAndReCredFilterData } from "@helpers/configMiddlewares/employeeFiles";
import { ICheckboxSelectDataStructure } from "@helpers/uiInterfaces/common";
import { IDashboardInitialAndReCredFilterEditorProps } from "@helpers/uiInterfaces/employeeFiles";

import CheckboxSelect from "@common/checkbox-select/CheckboxSelect";
import FormSelectInputControl from "@common/form-controls/FormSelectInputControl";
import SearchField from "@common/search-field/SearchField";

import { parseSelectControlsData } from "@utilities/index";

import styles from "./DashboardInitialAndReCred.module.scss";
import { getAllEmployeeStatus } from "@services/master/master.services";

const DashboardInitialAndReCredFilterEditor: React.FC<
  IDashboardInitialAndReCredFilterEditorProps
> = (props) => {
  const { open, filterData, onSearch, onClose } = props;

  const dispatch = useDispatch<any>();

  const employeeStatus = useAppSelector(
    (state) => state.masters.allEmployeeStatus
  );

  const [_filterData, _setFilterData] = useState(filterData);

  useEffect(() => {
    loadDependencies();
  }, []);

  useEffect(() => {
    _setFilterData(filterData);
  }, [filterData]);

  async function loadDependencies() {
    await dispatch(getAllEmployeeStatus());
  }

  function handleSearchFieldControl(searchText: string = "") {
    _setFilterData((latestFilterData) => {
      let data = {
        ...latestFilterData,
        search: searchText,
      };

      onSearch(data);

      return data;
    });
  }

  function handleSelectControlChange(name: string, value: string) {
    _setFilterData((latestFilterData) => {
      return {
        ...latestFilterData,
        [name]: value,
      };
    });
  }

  function renderSearchFieldControl() {
    const searchFieldControlAttributes = {
      placeholder: "Search by First or Last Name",
      value: _filterData.search,
      onSearch: handleSearchFieldControl,
      onClear: handleSearchFieldControl,
      onChange(searchText: string) {
        _setFilterData({
          ..._filterData,
          search: searchText,
        });
      },
    };

    return (
      <div className="form-group">
        <div className="lbl">
          <label className="d-flex align-center">Search By First or Last Name</label>
        </div>
        <SearchField {...searchFieldControlAttributes} />
      </div>
    );
  }

  function renderControlsSection() {
    const cancelControlAttributes = {
      className: "btn btnorg w100 border-button",
      onClick: onClose,
    };

    const searchControlAttributes = {
      className: "btn btnorg w100",
      onClick() {
        onSearch(_filterData);
      },
    };

    const resetControlAttributes = {
      className: "btn btnorg w100",
      onClick() {
        const defaultValues = setDefaultDashboardInitialAndReCredFilterData();
        _setFilterData(defaultValues);
        onSearch(defaultValues);
      },
    };

    return (
      <div className="mdl-footer">
        <button {...cancelControlAttributes}>Cancel</button>
        <button {...resetControlAttributes}>Reset</button>
        <button {...searchControlAttributes}>Search</button>
      </div>
    );
  }

  function renderContent() {
    const employeeTypeControlAttributes = {
      label: "Provider Type",
      name: "employeeType",
      disableDefaultSelectOption: true,
      data: statusTypes,
      value: _filterData.employeeType,
      onChange: handleSelectControlChange,
    };

    const locationsControlAttributes = {
      label: "Locations",
      data: _filterData.locations,
      onSelect(data: Array<ICheckboxSelectDataStructure>) {
        _setFilterData({
          ..._filterData,
          locations: data,
        });
      },
    };

    const departmentsControlAttributes = {
      label: "Departments",
      data: _filterData.department,
      onSelect(data: Array<ICheckboxSelectDataStructure>) {
        _setFilterData({
          ..._filterData,
          department: data,
        });
      },
    };

    const employeeStatusControlAttributes = {
      label: "Provider Status",
      name: "employeeStatus",
      disableDefaultSelectOption: true,
      data: parseSelectControlsData(employeeStatus),
      value: _filterData.employeeStatus,
      onChange: handleSelectControlChange,
    };

    return (
      <Offcanvas.Body
        className={styles.dashboardInitialAndReCredFilterEditorMain}
      >
        <div>
          {renderSearchFieldControl()}
          <FormSelectInputControl {...employeeTypeControlAttributes} />
          <CheckboxSelect {...locationsControlAttributes} />
          <CheckboxSelect {...departmentsControlAttributes} />
          <FormSelectInputControl {...employeeStatusControlAttributes} />
        </div>

        {renderControlsSection()}
      </Offcanvas.Body>
    );
  }

  return (
    <Offcanvas show={open} onHide={onClose} placement="end">
      <Offcanvas.Header closeButton className="border-bottom">
        <Offcanvas.Title>Search & Filter List</Offcanvas.Title>
      </Offcanvas.Header>

      {renderContent()}
    </Offcanvas>
  );
};

export default DashboardInitialAndReCredFilterEditor;
