import React from 'react';

import { Offcanvas } from 'react-bootstrap';

import { IEmployeeGapsInCredEditorProps } from '@helpers/uiInterfaces/employeeFiles';

import GapsInCred from '@pages/manageDocuments/gapsInCred/GapsInCred';

const EmployeesGapsInCredEditor: React.FC<IEmployeeGapsInCredEditorProps> = (props) => {

  const {open, onClose} = props;

  return (
    <Offcanvas show={open} onHide={onClose} placement="end" style={{ width: '50vw' }}>

      <Offcanvas.Header closeButton className='border-bottom'>
        <Offcanvas.Title>Provider Alerts</Offcanvas.Title>
      </Offcanvas.Header>
      
      <Offcanvas.Body>
        <GapsInCred />
      </Offcanvas.Body>

    </Offcanvas>
  );
};

export default EmployeesGapsInCredEditor;