import applicationTableColumnsTypes from "@constants/application-table-columns-types";

import { IApplicationTableColumn } from "@helpers/uiInterfaces/common";

const orderSummaryListColumns: Array<IApplicationTableColumn> = [
  {
    id: "fullName",
    label: "Provider Name",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "primarySourceVerificationType",
    label: "Primary Source Verification Type",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "source",
    label: "Source",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "orderPlacedDate",
    label: "Date Order Placed",
    type: applicationTableColumnsTypes.CUSTOM,
  },
  {
    id: "orderFulfilledDate",
    label: "Date Order Fulfilled",
    type: applicationTableColumnsTypes.CUSTOM,
  },
  {
    id: "status",
    label: "Status",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "amount",
    label: "Amount",
    type: applicationTableColumnsTypes.STRING,
  },
];

export default orderSummaryListColumns;
