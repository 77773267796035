import React, { Suspense, useState } from "react";

import reqCredentialingTabs from "@constants/json-data/req-to-credentialing";

import { IRequestToCredentialingTabs } from "@helpers/uiInterfaces/req-to-credentialing";

import ContentHeader from "@common/ContentHeader";
import Spinner from "@common/spinner/Spinner";

const PendingAndCreatedRequestTab = React.lazy(
  () => import("./PendingAndCreatedRequestTab")
);

const RequestCredentialing = () => {
  const [tabIndex, setTabIndex] = useState(1);

  function renderTabsContent() {
    switch (tabIndex) {
      case 1:
        return <PendingAndCreatedRequestTab type="Pending" tabIndex={tabIndex} />;
      case 2:
        return <PendingAndCreatedRequestTab type="Created" tabIndex={tabIndex} />;
      default:
        break;
    }
  }

  function renderTab(tab: IRequestToCredentialingTabs, index: number) {
    let tabClassName = "nav-link";

    if (tabIndex === tab.index) {
      tabClassName += " active";
    }

    const tabLinkControlAttributes = {
      className: tabClassName,
      role: "tab",
      key: index,
      onClick() {
        setTabIndex(tab.index);
      },
    };

    return (
      <li className="nav-item" key={index}>
        <a {...tabLinkControlAttributes}>{tab.label}</a>
      </li>
    );
  }

  function renderTabHeader() {
    return (
      <div className="scrollbar-2 w-100">
        <div className="sub_scrollbar mt-2">
          <ul className="nav nav-tabs" role="tablist">
            {reqCredentialingTabs.map((tab, index) => renderTab(tab, index))}
          </ul>
        </div>
      </div>
    );
  }

  function renderContent() {
    return (
      <div className="tab_form_content px-2">
        <div className="inner_tabsWrapper">
          {renderTabHeader()}

          <div className="mt-3">
            <Suspense fallback={<Spinner />}>{renderTabsContent()}</Suspense>
          </div>
        </div>
      </div>
    );
  }

  const contentHeaderAttributes = {
    title: "Request Credentialing",
    instructions: [],
  };

  return (
    <div>
      <ContentHeader {...contentHeaderAttributes} />
      {renderContent()}
    </div>
  );
};

export default RequestCredentialing;
