import { IRequestToCredentialingTabs } from "@helpers/uiInterfaces/req-to-credentialing";

const reqCredentialingTabs: Array<IRequestToCredentialingTabs> = [
  {
    label: "Pending Request(s)",
    index: 1,
  },
  {
    label: "Accepted Request(s)",
    index: 2,
  },
];

export default reqCredentialingTabs;
