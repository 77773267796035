import React, { useEffect, useState } from "react";

import Multiselect from "multiselect-react-dropdown";

import { IMultiSelectInputProps } from "helpers/uiInterfaces/common";

import "./MultiSelect.scss";

const MultiSelectInput: React.FC<IMultiSelectInputProps> = (props) => {
  const {
    data,
    selectedValues,
    placeholder,
    onChange,
    onBlur,
    disabled,
    error,
  } = props;

  const [_data, _setData] = useState(data);
  const [_selectedValues, _setSelectedValues] = useState(selectedValues);

  useEffect(() => {
    _setData(data);
  }, [data]);

  useEffect(() => {
    _setSelectedValues(selectedValues);
  }, [selectedValues]);

  function renderErrorMessage() {
    if (!error) {
      return;
    }

    return <label className="error-message">{error}</label>;
  }

  const multiSelectControlAttributes = {
    options: _data,
    selectedValues: _selectedValues,
    placeholder: placeholder ? placeholder : "Please select",
    displayValue: "label",
    className: error ? "multiSelectMainError" : "multiSelectMain",
    disable: disabled ? true : false,
    onSelect(selectedList: any) {
      onChange(selectedList);
    },
    onRemove(selectedList: any) {
      onChange(selectedList);
    },
    onBlur,
  };

  return (
    <div>
      <Multiselect {...multiSelectControlAttributes} />
      {renderErrorMessage()}
    </div>
  );
};

export default MultiSelectInput;
