import { IReqToCredModel } from "@helpers/interfaces/req-to-credentialing";
import currentStatusList from "@constants/json-data/masters-data/current-status-list";
import { validateEmail } from "@utilities/form-validators";
import rushReqOptions from "@constants/json-data/masters-data/request-type-options";
import moment from "moment";

interface IReqForCredFormErrors {
  firstName: string;
  lastName: string;
  email: string;
  credPrimaryEmail: string;
  credentialsId: string;
  affiliatedInstitutionError: string;
  credSecondaryEmail: string;
  serviceSiteName: string;
  serviceSiteAddress: string;
  serviceSiteState: string;
  serviceSiteCity: string;
  serviceSiteZip: string;
  anticipatedStartDate: string;
}

export function parseApplicantList(
  applicants: Array<IReqToCredModel>,
  userName: string
) {
  const _applicants = [];

  for (let applicant of applicants) {
    const _applicant = {
      ...applicant,
      processingType: rushReqOptions.find(
        (data) => data.value === applicant.requestType
      )?.label,
      existingStatus: applicant.isExisting ? "Yes" : "No",
      currentStatusValue: currentStatusList.find(
        (data) => data.value === applicant.currentStatus
      )?.label,
      requested_date: applicant.createdDate
        ? moment(applicant.createdDate).format("MM/DD/YYYY")
        : "",
    };

    _applicants.push(_applicant);
  }

  return _applicants;
}

export function validateReqForCredForm(values: IReqToCredModel) {
  const errors = {} as IReqForCredFormErrors;

  if (!values.firstName) {
    errors.firstName = "Required";
  }

  if (!values.lastName) {
    errors.lastName = "Required";
  }

  if (values.email) {
    if (validateEmail(values.email) === true) {
      errors.email = "Invalid email";
    }
  } else {
    errors.email = "Required";
  }

  if (values.credPrimaryEmail) {
    if (validateEmail(values.credPrimaryEmail) === true) {
      errors.credPrimaryEmail = "Invalid email";
    }
  } else {
    errors.credPrimaryEmail = "Required";
  }

  if (!values.credentialsId) {
    errors.credentialsId = "Required";
  }

  if (
    !values.affiliatedInstitutionIds ||
    values.affiliatedInstitutionIds?.length === 0
  ) {
    errors.affiliatedInstitutionError = "Required";
  }

  if (!values.anticipatedStartDate) {
    errors.anticipatedStartDate = "Required";
  }

  // if (values.credSecondaryEmail) {
  //   if (validateEmail(values.credSecondaryEmail) === true) {
  //     errors.credSecondaryEmail = "Invalid email";
  //   }
  // } else {
  //   errors.credSecondaryEmail = "Required";
  // }

  // if (values.isExistingLocation) {
  //   if (!values.serviceSiteName) {
  //     errors.serviceSiteName = "Required";
  //   }

  //   if (!values.serviceSiteAddress) {
  //     errors.serviceSiteAddress = "Required";
  //   }

  //   if (!values.serviceSiteState) {
  //     errors.serviceSiteState = "Required";
  //   }

  //   if (!values.serviceSiteCity) {
  //     errors.serviceSiteCity = "Required";
  //   }

  //   if (!values.serviceSiteZip) {
  //     errors.serviceSiteZip = "Required";
  //   }
  // }

  return errors;
}

export const parseSelectedClientsData = (
  data: string,
  list: Array<any>,
  defaultId?: string
) => {
  const inputValues = data?.split(",").filter(Boolean);
  const lowerCaseIds = inputValues?.map((data: any) => data.toLowerCase());
  const matchingClients = list
    .filter(
      (client) => lowerCaseIds?.includes(client.id) || defaultId === client.id
    )
    .map((client) => ({
      label: client.name,
      value: client.id,
    }));
  return matchingClients;
};

export const parseSelectedAffiliatedIds = (data: string, list: Array<any>) => {
  const selectedLocations = list
    .filter((location) => data?.includes(location.id))
    .map((location) => ({
      value: location.id,
      label: location.name,
    }));
  return selectedLocations;
};
