import { IMastersDataModel } from "@helpers/interfaces/masters/Masters";
import { IOrganizationContactModel } from "@helpers/interfaces/organizations";
import { IServiceSiteModel } from "@helpers/interfaces/serviceSites";
import { validateEmail } from "@utilities/form-validators";

import { parseSelectControlsData } from "@utilities/index";

interface IOrganizationalContactError {
  titleId: string;
  contactName: string;
  displayTitle: string;
  locationId: string;
  contactPhone: string;
  email: string;
}

export const ALL_CONTACTS_TITLE = "allContacts";

export function parseOrganizationalContactLocation(
  serviceSiteAddress: Array<IServiceSiteModel>
) {
  const data = [];

  for (let contact of serviceSiteAddress) {
    const _fullAddress = `${contact.address} ${contact.cityName} ${contact.stateName} ${contact.zip}`;

    const item = {
      label: _fullAddress,
      value: contact.locationId,
    };

    data.push(item);
  }

  return data;
}

export function validateOrganizationalContactForm(
  values: IOrganizationContactModel
) {
  const errors = {} as IOrganizationalContactError;

  if (!values.titleId) {
    errors.titleId = "Required";
  }

  if (!values.contactName) {
    errors.contactName = "Required";
  }

  if (!values.displayTitle) {
    errors.displayTitle = "Required";
  }
  if (!values.contactPhone) {
    errors.contactPhone = "Required";
  }

  if (!values.email) {
    errors.email = "Required";
  } else if (validateEmail(values.email) === true) {
    errors.email = "Invalid Email";
  }

  if (!values.locationId) {
    errors.locationId = "Required";
  }

  return errors;
}

export function parseContactTitleData(contactTitles: Array<IMastersDataModel>) {
  // manually passing ALL CONTACTS option so user can reset the contact title filter
  const allContactTitle = {
    label: "All Contacts",
    value: ALL_CONTACTS_TITLE,
  };

  const data = [allContactTitle, ...parseSelectControlsData(contactTitles)];

  return data;
}
