import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ICredTrackingHeaderModel,
  ICredTrackingLogsPaginationModel,
  ICredTrackingModel,
  ICredTrackingPaginationModel,
} from "@helpers/interfaces/employeeFiles";
import { setDefaultCredTrackingData } from "@helpers/configMiddlewares/employeeFiles";

const initialState = {
  credentialingTrackingData: {} as ICredTrackingModel,
  credentialingTrackingList: {} as ICredTrackingPaginationModel,
  credentialingTrackingLogsList: {} as ICredTrackingLogsPaginationModel,
  credentialingTrackingHeaderData: {} as ICredTrackingHeaderModel,
  error: null as any,
  loading: false,
  editorLoading: false,
};

const credentialingTrackingSlice = createSlice({
  name: "addEditEmployees",
  initialState,
  reducers: {
    updateCredentialingTrackingData: (
      state,
      action: PayloadAction<ICredTrackingModel>
    ) => {
      state.credentialingTrackingData = action.payload;
    },

    updateCredentialingTrackingList: (
      state,
      action: PayloadAction<ICredTrackingPaginationModel>
    ) => {
      state.credentialingTrackingList = action.payload;
    },

    updateCredentialingTrackingLogsList: (
      state,
      action: PayloadAction<ICredTrackingLogsPaginationModel>
    ) => {
      state.credentialingTrackingLogsList = action.payload;
    },

    updateCredentialingTrackingHeaderData: (
      state,
      action: PayloadAction<ICredTrackingHeaderModel>
    ) => {
      state.credentialingTrackingHeaderData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    resetEditorState: (state) => {
      state.editorLoading = false;
      state.error = null;
      state.credentialingTrackingData = setDefaultCredTrackingData();
    },
  },
});

export const credentialingTrackingActions = credentialingTrackingSlice.actions;
export default credentialingTrackingSlice.reducer;
