import React, { useState, useEffect } from "react";
import ContentHeader from "@common/ContentHeader";
import styles from "./PSV.module.scss";
import AccordianComponent from "@common/accordian/Accordian";
import { useAppSelector } from "@hooks/index";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getNPDBPersonalInfo } from "@services/setupIntegrations/NPDB";
import { getAllStateLicense } from "@services/credentialing-profile/perProfInfoTab/StateLicense";
import FormCheckboxControl from "@common/form-controls/FormCheckboxControl";
import { useNavigate, useLocation } from "react-router";
import routesNames from "@constants/routes-names";
import {
  createIdsString,
  parseSelectDocTypeCodeControlsData,
} from "@utilities/index";
import psvFooterInstructions from "@constants/instructions/psvFooterInstructions";
import FormSelectInputControl from "@common/form-controls/FormSelectInputControl";
import { getUnderGraduateSchool } from "@services/credentialing-profile/eduTrainTab/Undergraduate";
import { getUSCanadianSchoolAll } from "@services/credentialing-profile/eduTrainTab/GraduateType";
import { getAllProfessionalReferences } from "@services/credentialing-profile/workHistoryRefTab/PrefessionalReferences";
import { psvOrderConfirm, psvOrderConfirmForCW } from "@services/psv";
import { toastMessageActions } from "@features/ui/ToastMessage";
import toastTypes from "@constants/toast-types";
import { getAllClinicalWorkHistory } from "@services/credentialing-profile/workHistoryRefTab/ClinicalWorkHistory";
import { getComlianceWatchdogSetupDetails } from "@services/setupIntegrations/ComplianceWatchdog";
import { getDocumentTypeByHrmrUserId } from "@services/manage-documents";
import FormRadioControl from "@common/form-controls/FormRadioControl";
import CredUserProfile from "@pages/credentialingProfile/CredUserProfile";
import NoContentSection from "@common/no-content-section/NoContentSection";
interface IRadioOption {
  activityId: number | undefined;
  name: string | undefined;
}
const PSV: React.FC<any> = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const userRecord = useAppSelector(
    (state) => state.authenticationRecord.userRecord
  );
  const { state } = useLocation();
  const [licenseIds, setLicenseIds] = useState<Array<string>>([]);
  const [selectedGraduateIds, setSelectedGraduateIds] = useState<Array<string>>(
    []
  );
  const [instructionChecked, setInstructionChecked] = useState<boolean>(false);
  const [selectedPrimaryOptions, setSelectedPrimaryOptions] = useState<
    Array<string>
  >([]);
  const [selectedUnderGraduateData, setSelectedUnderGraduateData] =
    useState<boolean>(false);
  const [compliancePrimarySource, setCompliancePrimarySource] =
    useState<IRadioOption | null>(null);
  const [isComplianceWatchdogOn, setIsComplianceWatchdogOn] = useState(false);
  const [selectedVendorData, setSelectedVendorData] =
    useState<string>("veritableScreening");
  const [selectedComplianePrimarySource, setSelectedComplianePrimarySource] =
    useState("");
  const _isClinicalStaff: boolean = state?.isClinicalStaff!;

  const orderSummaryState = useAppSelector((state) => state.orderSummary);
  useEffect(() => {
    if (userRecord.hrmrUserId) {
      getComplianceDependencies();
      dispatch(getNPDBPersonalInfo(userRecord.hrmrUserId));
      dispatch(getAllClinicalWorkHistory(userRecord.hrmrUserId));
      dispatch(getAllStateLicense(userRecord.hrmrUserId));
      dispatch(getUnderGraduateSchool(userRecord.hrmrUserId));
      dispatch(getUSCanadianSchoolAll(userRecord.hrmrUserId));
      dispatch(getAllProfessionalReferences(userRecord.hrmrUserId));
    }
  }, [userRecord]);

  const getComplianceDependencies = async () => {
    const res = await dispatch(getComlianceWatchdogSetupDetails());
    if (res.status === 200) {
      setIsComplianceWatchdogOn(res.data.isONorOff);
      setSelectedVendorData(
        res.data.isONorOff ? "complianceWatchdog" : "veritableScreening"
      );
      dispatch(getDocumentTypeByHrmrUserId(userRecord.hrmrUserId));
    }
  };

  const stateLicenseState = useAppSelector(
    (state) => state.credentialProfile.perProf.stateLicense
  );

  const organizationState = useAppSelector(
    (state) => state.credentialProfile.workHistoryReference.clinicalWorkHistory
  );

  const personalInfoData = useAppSelector(
    (state) => state.setupIntegration.npdbSetup.npdbPersonalInfo
  );

  const underGratuateState = useAppSelector(
    (state) => state.credentialProfile.eduTrain.undergraduate
  );

  const graduateState = useAppSelector(
    (state) => state.credentialProfile.eduTrain.graduateType
  );

  const professionalRefState = useAppSelector(
    (state) =>
      state.credentialProfile.workHistoryReference.professionalReferences
  );

  const manageDocsState = useAppSelector(
    (state) => state.manageDocuments.manageDocumentsState
  );

  const vendorData = isComplianceWatchdogOn
    ? [
        {
          value: "complianceWatchdog",
          label: "Compliance Watchdog",
        },
        {
          value: "veritableScreening",
          label: "Veritable Screening",
        },
      ]
    : [
        {
          value: "veritableScreening",
          label: "Veritable Screening",
        },
      ];

  const primarySourceVerificationOptions = [
    {
      label: "Education",
      value: "education",
    },
    {
      label: "License",
      value: "license",
    },
    {
      label: "Employment",
      value: "employment",
    },
    {
      label: "Professional Reference",
      value: "professionalReference",
    },
    {
      label: "Background Check(Basic)",
      value: "backgroundCheck",
    },
    {
      label: " International Education Verification",
      value: "internationalEducationVerification",
    },
    {
      label: "Background Check(Standard)",
      value: "backgroundCheckStandard",
    },
  ];

  const handleAppVendorControlChange = (name: string, value: string) => {
    setSelectedVendorData(value);
    setSelectedPrimaryOptions([]);
  };

  const handleRadioChange = (name: string, value: string) => {
    const selectedData = manageDocsState.documentTypeDataList.find(
      (data) => data.docTypeId === +value
    );
    const docName = selectedData?.appVerificationName
      ? selectedData.appVerificationName
      : "";
    const selectedCompliancePrimarySource = {
      activityId: selectedData?.activityId,
      name: `${selectedData?.activityName} - ${selectedData?.docType} (${docName})`,
    };
    setCompliancePrimarySource(selectedCompliancePrimarySource);
    setSelectedComplianePrimarySource(value);
  };

  const handlePlaceOrder = () => {
    if (!getIsValidOrder()) {
      dispatch(
        psvOrderConfirm(
          personalInfoData.hrmrUserId,
          selectedPrimaryOptions.includes("education"),
          selectedPrimaryOptions.includes("internationalEducationVerification"),
          selectedPrimaryOptions.includes("license"),
          selectedPrimaryOptions.includes("backgroundCheck"),
          selectedPrimaryOptions.includes("employment"),
          selectedPrimaryOptions.includes("professionalReference"),
          selectedPrimaryOptions.includes("backgroundCheckStandard"),
          createIdsString(selectedGraduateIds),
          createIdsString(licenseIds)
        )
      );
    }
  };

  const handleCWOrderPlaced = () => {
    if (!compliancePrimarySource) {
      dispatch(
        toastMessageActions.showToast({
          message: "Please select atleast one source verification",
          type: toastTypes.WARNING,
        })
      );
    } else if (!instructionChecked) {
      dispatch(
        toastMessageActions.showToast({
          message:
            "Please read the Terms and Conditions and select the checkbox to proceed!",
          type: toastTypes.WARNING,
        })
      );
    } else {
      if (compliancePrimarySource.activityId && compliancePrimarySource.name) {
        dispatch(
          psvOrderConfirmForCW(
            personalInfoData.hrmrUserId,
            compliancePrimarySource.activityId,
            compliancePrimarySource.name
          )
        );
      }
    }
  };

  const data = [
    {
      path: routesNames.dashboardRoute,
      breadcrumb: "Dashboard",
    },
    {
      path: _isClinicalStaff
        ? routesNames.clinicalStaff
        : routesNames.nonClinicalStaff,
      breadcrumb: _isClinicalStaff ? "Licensed Providers" : "Non Licensed Staff",
    },
    {
      path: routesNames.psvRoute,
      breadcrumb: "PSV",
    },
  ];

  const contentHeaderAttributes = {
    title: "PSV",
    instructions: [],
    renderExtraContent: <CredUserProfile />,
    breadcrumbData: data,
  };

  const renderEmploymentData = () => {
    return (
      <div>
        {organizationState.clinicalWorkHistoryList.length === 0 ? (
          <NoContentSection />
        ) : (
          <div>
            {organizationState.clinicalWorkHistoryList.map((data, index) => {
              const startDate = data.startDate
                ? moment(data.startDate).format("MM/DD/YYYY")
                : "";
              const endDate = data.endDate
                ? moment(data.endDate).format("MM/DD/YYYY")
                : "";

              return (
                <div key={index} className="row align-items-end">
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className={styles.labelContainer}>
                      <span className={styles.label}>Organization Name :</span>
                      <strong>{data.employerName}</strong>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className={styles.labelContainer}>
                      <span className={styles.label}>Start Date :</span>
                      <strong> {startDate}</strong>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className={styles.labelContainer}>
                      <span className={styles.label}>End Date :</span>
                      <strong> {endDate}</strong>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const renderPersonalContent = () => {
    const dateOfBirth = personalInfoData.dob
      ? moment(personalInfoData.dob).format("MM/DD/YYYY")
      : "";

    return (
      <div className="row align-items-end">
        <div className="col-md-6 col-sm-12 col-xs-12">
          <div className={styles.labelContainer}>
            <span className={styles.label}>First Name :</span>
            <strong> {personalInfoData.firstName}</strong>
          </div>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12">
          <div className={styles.labelContainer}>
            <span className={styles.label}>Middle Name :</span>
            <strong> {personalInfoData.middleName}</strong>
          </div>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12">
          <div className={styles.labelContainer}>
            <span className={styles.label}>Last Name :</span>
            <strong> {personalInfoData.lastName}</strong>
          </div>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12">
          <div className={styles.labelContainer}>
            <span className={styles.label}>Suffix :</span>
            <strong> {personalInfoData.suffix}</strong>
          </div>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12">
          <div className={styles.labelContainer}>
            <span className={styles.label}>Gender :</span>
            <strong> {personalInfoData.genderName}</strong>
          </div>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12">
          <div className={styles.labelContainer}>
            <span className={styles.label}>Date of Birth :</span>
            <strong> {dateOfBirth}</strong>
          </div>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12">
          <div className={styles.labelContainer}>
            <span className={styles.label}>Email :</span>
            <strong> {personalInfoData.email}</strong>
          </div>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12">
          <div className={styles.labelContainer}>
            <span className={styles.label}>Telephone :</span>
            <strong> {personalInfoData.number}</strong>
          </div>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12">
          <div className={styles.labelContainer}>
            <span className={styles.label}>Street :</span>
            <strong>{personalInfoData.street}</strong>
          </div>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12">
          <div className={styles.labelContainer}>
            <span className={styles.label}>City :</span>
            <strong> {personalInfoData.cityName}</strong>
          </div>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12">
          <div className={styles.labelContainer}>
            <span className={styles.label}>State :</span>
            <strong>{personalInfoData.stateName}</strong>
          </div>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12">
          <div className={styles.labelContainer}>
            <span className={styles.label}>Zip Code :</span>
            <strong> {personalInfoData.zip}</strong>
          </div>
        </div>
      </div>
    );
  };

  const renderEmploymentInfo = () => {
    const accordianProps = {
      accordianData: [
        {
          accordianHeading: "Employment Verification",
          accordianData: renderEmploymentData(),
        },
      ],
      defaultActiveKey: "0",
    };

    return (
      <div className="m-4">
        <AccordianComponent {...accordianProps} />
      </div>
    );
  };

  const renderPersonalInfo = () => {
    const accordianProps = {
      accordianData: [
        {
          accordianHeading: "Personal Information",
          accordianData: renderPersonalContent(),
        },
      ],
      defaultActiveKey: "0",
    };

    return (
      <div className="m-4 mt-0">
        <AccordianComponent {...accordianProps} />
      </div>
    );
  };

  const renderStateLicenseContent = () => {
    return (
      <div>
        {stateLicenseState.stateLicenseDataList.length === 0 ? (
          <NoContentSection />
        ) : (
          <div>
            {stateLicenseState.stateLicenseDataList.map((license, index) => {
              const licenseCheckboxControlAttributes = {
                label: "Please Select",
                name: license.stateLicenseId.toString(),
                checked: licenseIds.find(
                  (licenseId) => licenseId === license.stateLicenseId.toString()
                )
                  ? true
                  : false,

                onChange(event: React.ChangeEvent<HTMLInputElement>) {
                  let licenceIdData = [...licenseIds];
                  if (
                    event.target.checked &&
                    !licenceIdData.includes(event.target.name)
                  ) {
                    licenceIdData.push(event.target.name);
                  } else {
                    licenceIdData = licenceIdData.filter(
                      (license) => license !== event.target.name
                    );
                  }
                  setLicenseIds(licenceIdData);
                },
              };

              return (
                <div key={index}>
                  <FormCheckboxControl {...licenseCheckboxControlAttributes} />
                  <div className="row align-items-end">
                    <div className="col-md-6 col-sm-12 col-xs-12">
                      <div className={styles.labelContainer}>
                        <span className={styles.label}>
                          PIP State License Number :
                        </span>
                        <strong>{license.stateLicenseNumber}</strong>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12">
                      <div className={styles.labelContainer}>
                        <span className={styles.label}>
                          License Issuing State :
                        </span>
                        <strong>{license.stateLicenseIssueState}</strong>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const renderEducationVerificationContent = () => {
    const underGraduateCheckboxControlAttributes = {
      label: "Please select Undergraduate School details",
      name: "underGraduateData",
      checked: selectedUnderGraduateData,
      onChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedUnderGraduateData(event.target.checked);
      },
    };

    const ugStartDate = underGratuateState.underGraduateData.ugSchoolStartDate
      ? moment(underGratuateState.underGraduateData.ugSchoolStartDate).format(
          "MM/DD/YYYY"
        )
      : "";
    const ugEndDate = underGratuateState.underGraduateData.ugSchoolEndDate
      ? moment(underGratuateState.underGraduateData.ugSchoolEndDate).format(
          "MM/DD/YYYY"
        )
      : "";

    return (
      <div>
        <div className="mb-5">
          <h4>Undergraduate School details :-</h4>
          <FormCheckboxControl {...underGraduateCheckboxControlAttributes} />
          <div className="row align-items-end">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className={styles.labelContainer}>
                <span className={styles.label}>School/University Name :</span>
                <strong>
                  {underGratuateState.underGraduateData.ugSchoolName}
                </strong>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className={styles.labelContainer}>
                <span className={styles.label}>Telephone :</span>
                <strong>
                  {underGratuateState.underGraduateData.ugSchoolTelephone}
                </strong>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className={styles.labelContainer}>
                <span className={styles.label}>Street :</span>
                <strong>
                  {underGratuateState.underGraduateData.ugSchoolCountry}
                </strong>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className={styles.labelContainer}>
                <span className={styles.label}>City :</span>
                <strong>
                  {underGratuateState.underGraduateData.ugSchoolCity}
                </strong>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className={styles.labelContainer}>
                <span className={styles.label}>Zip :</span>
                <strong>
                  {underGratuateState.underGraduateData.ugSchoolZip}
                </strong>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className={styles.labelContainer}>
                <span className={styles.label}>Start Date :</span>
                <strong>{ugStartDate}</strong>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className={styles.labelContainer}>
                <span className={styles.label}>End Date :</span>
                <strong> {ugEndDate}</strong>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className={styles.labelContainer}>
                <span className={styles.label}>Degree Awarded :</span>
                <strong>
                  {underGratuateState.underGraduateData.ugSchoolDegreeAwarded}
                </strong>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h4>Graduate School details :-</h4>
          {graduateState.usCanadianDataList.length === 0 ? (
            <NoContentSection />
          ) : (
            <div>
              {graduateState.usCanadianDataList.map((graduateData, index) => {
                const graduateCheckboxControlAttributes = {
                  label: "Please select Graduate School details",
                  name: graduateData.ucSchooId.toString(),
                  checked: selectedGraduateIds.find(
                    (id) => id === graduateData.ucSchooId.toString()
                  )
                    ? true
                    : false,
                  onChange(event: React.ChangeEvent<HTMLInputElement>) {
                    let graduateIdData = [...selectedGraduateIds];
                    if (
                      event.target.checked &&
                      !graduateIdData.includes(event.target.name)
                    ) {
                      graduateIdData.push(event.target.name);
                    } else {
                      graduateIdData = graduateIdData.filter(
                        (graduateData) => graduateData !== event.target.name
                      );
                    }
                    setSelectedGraduateIds(graduateIdData);
                  },
                };
                const graduateEndDate = graduateData.ucSchoolEndDate
                  ? moment(graduateData.ucSchoolEndDate).format("MM/DD/YYYY")
                  : "";
                const graduateStartDate = graduateData.ucSchoolStartDate
                  ? moment(graduateData.ucSchoolStartDate).format("MM/DD/YYYY")
                  : "";
                return (
                  <div key={index} className="mb-4">
                    <FormCheckboxControl
                      {...graduateCheckboxControlAttributes}
                    />
                    <div className="row align-items-end">
                      <div className="col-md-6 col-sm-12 col-xs-12">
                        <div className={styles.labelContainer}>
                          <span className={styles.label}>
                            US Or Canadian School/University Name :
                          </span>
                          <strong> {graduateData.ucSchoolName}</strong>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12 col-xs-12">
                        <div className={styles.labelContainer}>
                          <span className={styles.label}>Start Date :</span>
                          <strong> {graduateStartDate}</strong>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12 col-xs-12">
                        <div className={styles.labelContainer}>
                          <span className={styles.label}>End Date :</span>
                          <strong> {graduateEndDate}</strong>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12 col-xs-12">
                        <div className={styles.labelContainer}>
                          <span className={styles.label}>Degree Awarded :</span>
                          <strong> {graduateData.ucSchoolDegreeAwarded}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderProfessionalReferenceVerificationContent = () => {
    return (
      <div>
        {professionalRefState.professionalReferencesDataList.length === 0 ? (
          <NoContentSection />
        ) : (
          <div>
            {professionalRefState.professionalReferencesDataList.map(
              (ref, index) => (
                <div key={index} className="row align-items-end">
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className={styles.labelContainer}>
                      <span className={styles.label}>Person Name :</span>
                      <strong> {`${ref.firstName} ${ref.lastName}`}</strong>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className={styles.labelContainer}>
                      <span className={styles.label}>Telephone Number :</span>
                      <strong> {ref.telephone}</strong>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className={styles.labelContainer}>
                      <span className={styles.label}>Relationship :</span>
                      <strong> {ref.professionalRelationship}</strong>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </div>
    );
  };

  const renderLicenseInfo = () => {
    const accordianProps = {
      accordianData: [
        {
          accordianHeading: "State License",
          accordianData: renderStateLicenseContent(),
        },
      ],
      defaultActiveKey: "0",
    };

    return (
      <div className="m-4">
        <AccordianComponent {...accordianProps} />
      </div>
    );
  };

  const renderEducationInfo = () => {
    const accordianProps = {
      accordianData: [
        {
          accordianHeading: "Education Verification",
          accordianData: renderEducationVerificationContent(),
        },
      ],
      defaultActiveKey: "0",
    };

    return (
      <div className="m-4">
        <AccordianComponent {...accordianProps} />
      </div>
    );
  };

  const renderProfessionalReferenceInfo = () => {
    const accordianProps = {
      accordianData: [
        {
          accordianHeading: "Professional Reference Verification",
          accordianData: renderProfessionalReferenceVerificationContent(),
        },
      ],
      defaultActiveKey: "0",
    };

    return (
      <div className="m-4">
        <AccordianComponent {...accordianProps} />
      </div>
    );
  };

  const renderInstruction = () => {
    const instructionCheckboxControl = {
      label: "Terms and Conditions:",
      name: "term&Conditions",
      checked: instructionChecked,
      onChange(event: React.ChangeEvent<HTMLInputElement>) {
        setInstructionChecked(event.target.checked);
      },
    };

    return (
      <div className={` m-4`}>
        <div className={styles.internalBox}>
          <div className="mx-3">
            <FormCheckboxControl {...instructionCheckboxControl} />
          </div>
          <ul>
            {psvFooterInstructions.map((instruction, index) => (
              <li key={index}>{instruction}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const getIsValidOrder = () => {
    if (
      selectedPrimaryOptions.includes("education") ||
      selectedPrimaryOptions.includes("internationalEducationVerification")
    ) {
      const selectedGraduatesSchoolData =
        graduateState.usCanadianDataList.filter((item) =>
          selectedGraduateIds.includes(item.ucSchooId.toString())
        );
      const isEmptySchoolNames = selectedGraduatesSchoolData.filter(
        (data) => !data.ucSchoolName
      );
      if (
        selectedUnderGraduateData &&
        !underGratuateState.underGraduateData.ugSchoolName
      ) {
        dispatch(
          toastMessageActions.showToast({
            message:
              "There is no Undergraduate University or School name details. Please fill this details",
            type: toastTypes.WARNING,
          })
        );
        return true;
      } else if (
        !selectedUnderGraduateData &&
        graduateState.usCanadianDataList.length === 0
      ) {
        dispatch(
          toastMessageActions.showToast({
            message:
              "Sorry! Looks like there is no data for Graduate School Verification. Please click on Edit and update your credential profile",
            type: toastTypes.WARNING,
          })
        );
        return true;
      } else if (!selectedUnderGraduateData && isEmptySchoolNames.length > 0) {
        dispatch(
          toastMessageActions.showToast({
            message:
              "There is no US Or Canadian University or School name details. Please fill this details",
            type: toastTypes.WARNING,
          })
        );
        return true;
      } else if (
        !selectedUnderGraduateData &&
        selectedGraduateIds.length === 0
      ) {
        dispatch(
          toastMessageActions.showToast({
            message:
              "Please select at least one of the education type for verification",
            type: toastTypes.WARNING,
          })
        );
        return true;
      }
    }
    if (selectedPrimaryOptions.includes("license")) {
      if (stateLicenseState.stateLicenseDataList.length === 0) {
        dispatch(
          toastMessageActions.showToast({
            message:
              "Sorry! Looks like there is no data for License Verification. Please click on Edit and update your credential profile",
            type: toastTypes.WARNING,
          })
        );
        return true;
      } else if (
        stateLicenseState.stateLicenseDataList.length > 0 &&
        licenseIds.length === 0
      ) {
        dispatch(
          toastMessageActions.showToast({
            message: "Please select at least one State License",
            type: toastTypes.WARNING,
          })
        );
        return true;
      }
    }
    if (selectedPrimaryOptions.includes("employment")) {
      const emptyEmployerName =
        organizationState.clinicalWorkHistoryList.filter(
          (ref) => !ref.employerName
        );
      const emptyStartDate = organizationState.clinicalWorkHistoryList.filter(
        (ref) => !ref.startDate
      );

      if (emptyEmployerName.length > 0) {
        dispatch(
          toastMessageActions.showToast({
            message:
              "There is no Employer Name. Please click on Edit and update your credential profile",
            type: toastTypes.WARNING,
          })
        );
        return true;
      } else if (emptyStartDate.length > 0) {
        dispatch(
          toastMessageActions.showToast({
            message:
              "There is no Employment Start Date. Please click on Edit and update your credential profile",
            type: toastTypes.WARNING,
          })
        );
        return true;
      }
    }
    if (selectedPrimaryOptions.includes("professionalReference")) {
      const emptyFirstName =
        professionalRefState.professionalReferencesDataList.filter(
          (ref) => !ref.firstName
        );
      const emptyLastName =
        professionalRefState.professionalReferencesDataList.filter(
          (ref) => !ref.lastName
        );
      if (professionalRefState.professionalReferencesDataList.length === 0) {
        dispatch(
          toastMessageActions.showToast({
            message:
              "Sorry! Looks like there is no data for Professional Reference Verification. Please click on Edit and update your credential profile",
            type: toastTypes.WARNING,
          })
        );
        return true;
      }
      if (emptyFirstName.length > 0 || emptyLastName.length > 0) {
        dispatch(
          toastMessageActions.showToast({
            message:
              "Sorry! Looks like there is no data for Professional Reference Verification. Please click on Edit and update your credential profile",
            type: toastTypes.WARNING,
          })
        );
        return true;
      }
    }
    if (selectedPrimaryOptions.includes("backgroundCheck")) {
      if (!personalInfoData.firstName) {
        dispatch(
          toastMessageActions.showToast({
            message:
              "Sorry! Looks like there is no First Name for Background Check Verification. Please click on Edit and update your credential profile",
            type: toastTypes.WARNING,
          })
        );
        return true;
      }
      if (!personalInfoData.lastName) {
        dispatch(
          toastMessageActions.showToast({
            message:
              "Sorry! Looks like there is no Last Name for Background Check Verification. Please click on Edit and update your credential profile",
            type: toastTypes.WARNING,
          })
        );
        return true;
      }
      if (!personalInfoData.email) {
        dispatch(
          toastMessageActions.showToast({
            message:
              "Sorry! Looks like there is no Email for Background Check Verification. Please click on Edit and update your credential profile",
            type: toastTypes.WARNING,
          })
        );
        return true;
      }
      if (!personalInfoData.dob) {
        dispatch(
          toastMessageActions.showToast({
            message:
              "Sorry! Looks like there is no Date of Birth for Background Check Verification. Please click on Edit and update your credential profile",
            type: toastTypes.WARNING,
          })
        );
        return true;
      }
    }
    if (!instructionChecked) {
      dispatch(
        toastMessageActions.showToast({
          message:
            "Please read the Terms and Conditions and select the checkbox to proceed!",
          type: toastTypes.WARNING,
        })
      );
      return true;
    }
    return false;
  };

  const renderCTAControls = () => {
    const placeOrderBtnAttributes = {
      className: "btn btnorg",
      disabled: orderSummaryState.loading,
      onClick() {
        selectedVendorData === "veritableScreening"
          ? handlePlaceOrder()
          : handleCWOrderPlaced();
      },
    };

    const editBtnAttributes = {
      className: "btn btnorg",
      onClick() {
        navigate(routesNames.credentialingProfileRoute, {
          state: { isClinicalStaff: _isClinicalStaff },
        });
      },
    };

    const backControlAttributes = {
      className: "btn btngry w140",
      onClick() {
        navigate(-1);
      },
    };

    return (
      <div className={`m-4 ${styles.btnsContainer}`}>
        <button {...backControlAttributes}>Back</button>
        <button {...placeOrderBtnAttributes}>Place Order</button>
        {selectedVendorData === "veritableScreening" && (
          <button {...editBtnAttributes}>Edit</button>
        )}
      </div>
    );
  };

  const renderSelectContol = () => {
    const vendorTypeControlAttributes = {
      label: "1. Please select Vendor for Primary Source Verification",
      name: "appVerificationId",
      disableDefaultSelectOption: false,
      data: vendorData,
      value: selectedVendorData,
      onChange: handleAppVendorControlChange,
    };

    const compliancePrimarySourceControlAttributes = {
      label: "",
      name: "primaryWDSourceVerification",
      value: +selectedComplianePrimarySource,
      columnDisplay: true,
      displayInWrap: true,
      data: parseSelectDocTypeCodeControlsData(
        manageDocsState.documentTypeDataList
      ),
      onChange: handleRadioChange,
    };

    return (
      <div className="m-4">
        <div className={styles.selectWidth}>
          <FormSelectInputControl {...vendorTypeControlAttributes} />
        </div>
        <div>
          <div className={styles.labelBox}>
            2. Please select one more primary source verification(s) that you
            would like to conduct
          </div>
          {selectedVendorData === "veritableScreening" ? (
            <div className="row align-items-end">
              {primarySourceVerificationOptions.map((option, index) => {
                const checkboxControlAttribute = {
                  label: option.label,
                  name: option.value,
                  checked: selectedPrimaryOptions.find(
                    (data) => data === option.value
                  )
                    ? true
                    : false,
                  onChange(event: React.ChangeEvent<HTMLInputElement>) {
                    const primaryOptions = [...selectedPrimaryOptions];
                    if (primaryOptions.includes(event.target.name)) {
                      const index = primaryOptions.findIndex(
                        (item) => item === event.target.name
                      );

                      if (index === -1) {
                        return;
                      }

                      primaryOptions.splice(index, 1);
                    } else {
                      if (primaryOptions.includes("backgroundCheckStandard")) {
                        dispatch(
                          toastMessageActions.showToast({
                            message:
                              "You can't choose any other option with Background Check(Standard).",
                            type: toastTypes.WARNING,
                          })
                        );
                      } else if (
                        primaryOptions.length > 0 &&
                        event.target.name === "backgroundCheckStandard"
                      ) {
                        dispatch(
                          toastMessageActions.showToast({
                            message:
                              "You can't choose Background Check(Standard) with any other option.",
                            type: toastTypes.WARNING,
                          })
                        );
                      } else if (
                        primaryOptions.includes("education") &&
                        event.target.name ===
                          "internationalEducationVerification"
                      ) {
                        dispatch(
                          toastMessageActions.showToast({
                            message:
                              "You can choose either Education Verification or International Education Verification.",
                            type: toastTypes.WARNING,
                          })
                        );
                      } else if (
                        primaryOptions.includes(
                          "internationalEducationVerification"
                        ) &&
                        event.target.name === "education"
                      ) {
                        dispatch(
                          toastMessageActions.showToast({
                            message:
                              "You can choose either Education Verification or International Education Verification.",
                            type: toastTypes.WARNING,
                          })
                        );
                      } else {
                        primaryOptions.push(event.target.name);
                      }
                    }
                    setSelectedPrimaryOptions(primaryOptions);
                  },
                };

                return (
                  <div key={index} className="col-md-6 col-sm-12 col-xs-12">
                    <FormCheckboxControl
                      key={index}
                      {...checkboxControlAttribute}
                    />
                    {option.value === "backgroundCheckStandard" && (
                      <div className={styles.noteContent}>
                        <span className={styles.boldNote}>Note: </span>This
                        option is available on special request only.
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <FormRadioControl {...compliancePrimarySourceControlAttributes} />
          )}
        </div>
      </div>
    );
  };

  return (
    <div id={styles.psvMain}>
      <div className={styles.mainContainer}>
        <ContentHeader {...contentHeaderAttributes} />
        {renderSelectContol()}
        {renderPersonalInfo()}
        {(selectedPrimaryOptions.includes("education") ||
          selectedPrimaryOptions.includes(
            "internationalEducationVerification"
          )) &&
          renderEducationInfo()}
        {selectedPrimaryOptions.includes("license") && renderLicenseInfo()}
        {selectedPrimaryOptions.includes("employment") &&
          renderEmploymentInfo()}
        {selectedPrimaryOptions.includes("professionalReference") &&
          renderProfessionalReferenceInfo()}
        {renderInstruction()}
        {renderCTAControls()}
      </div>
    </div>
  );
};

export default PSV;
