import axiosClient from "@store/axiosClient";

import toastTypes from "@constants/toast-types";

import { toastMessageActions } from "@features/ui/ToastMessage";
import { organizationContactsAction } from "@features/organizations/OrganizationContacts";

import {
  IDeleteOrganizationalContactParamsModel,
  IOrganizationAlertData,
  IOrganizationContactModel,
} from "@helpers/interfaces/organizations";

import {
  handleCreateServiceResponse,
  handleDeleteServiceResponse,
  handleServerInteractionError,
} from "@utilities/services";

import { ALL_CONTACTS_TITLE } from "@pages/organizationalContacts/utilities";

function showLoading(dispatch: any) {
  dispatch(organizationContactsAction.updateLoading(true));
}

function hideLoading(dispatch: any) {
  dispatch(organizationContactsAction.updateLoading(false));
}

function showEditorLoading(dispatch: any) {
  dispatch(organizationContactsAction.updateEditorLoading(true));
}

function hideEditorLoading(dispatch: any) {
  dispatch(organizationContactsAction.updateEditorLoading(false));
}

export const getOrganizationContactData =
  (contactId: number) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      showEditorLoading(dispatch);

      try {
        const response = await axiosClient.get(
          "api/Organization/GetOrganizationContacts?ContactId=" + contactId
        );
        const data = response.data;
        const status = response.status;

        if (status === 200) {
          dispatch(
            organizationContactsAction.updateOrganizationContactData(
              response.data
            )
          );
          resolve(response);
        } else {
          dispatch(
            toastMessageActions.showToast({
              message: data.title,
              type: toastTypes.DANGER,
            })
          );
        }

        hideEditorLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideEditorLoading(dispatch);
      }
    });
  };

export const getOrganizationContactsByType =
  (contactType: number) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosClient.get(
          "api/Organization/GetOrganizationContactsByType?ContactType=" +
            contactType
        );
        const data = response.data;
        const status = response.status;

        if (status === 200) {
          dispatch(
            organizationContactsAction.updateOrganizationContactsList(
              response.data
            )
          );
          resolve(response);
        } else {
          dispatch(
            toastMessageActions.showToast({
              message: data.title,
              type: toastTypes.DANGER,
            })
          );
        }
      } catch (error) {
        handleServerInteractionError(dispatch);
      }
    });
  };

export const getAllOrganizationalContacts =
  (
    pageNumber: number,
    pageSize: number,
    titleId?: string,
    searchText?: string
  ) =>
  async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);

        let url = `api/Organization/GetOrganizationContactsPageable?PageNumber=${pageNumber}&PageSize=${pageSize}`;

        if (searchText) {
          url += `&SearchText=${searchText}`;
        }

        if (titleId && titleId !== ALL_CONTACTS_TITLE) {
          url += `&TitleId=${titleId}`;
        }

        const response = await axiosClient.get(url);
        const data = response.data;
        const status = response.status;

        if (status === 200) {
          dispatch(
            organizationContactsAction.updateOrganizationContactsPaginatedList(
              data
            )
          );

          resolve(response);
          return hideLoading(dispatch);
        }

        dispatch(
          toastMessageActions.showToast({
            message: data.title,
            type: toastTypes.DANGER,
          })
        );

        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const createOrganizationalContact =
  (data: IOrganizationContactModel) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showEditorLoading(dispatch);

        const response = await axiosClient({
          method: "post",
          url: "api/Organization/CreateOrganizationContacts",
          data: `${JSON.stringify(data)}`,
        });
        const status = response.status;

        handleCreateServiceResponse(
          dispatch,
          organizationContactsAction,
          status,
          response.data
        );

        hideEditorLoading(dispatch);
        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideEditorLoading(dispatch);
      }
    });
  };

export const deleteOrganizationalContact =
  (data: IDeleteOrganizationalContactParamsModel) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);

        const response = await axiosClient({
          method: "post",
          url: "api/Organization/DeleteOrganizationContacts",
          data: `${JSON.stringify(data)}`,
        });
        const status = response.status;

        handleDeleteServiceResponse(dispatch, status, response.data);

        hideLoading(dispatch);
        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const getOrganizationalContactAlerts =
  (contactId: number) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);

        let url = `api/Organization/GetContactDetailsAlertSettings?ContactId=${contactId}`;

        const response = await axiosClient.get(url);
        const data = response.data;
        const status = response.status;

        if (status === 200) {
          dispatch(
            organizationContactsAction.updateOrganizationalContactAlert(data)
          );

          resolve(response);
          return hideLoading(dispatch);
        }

        dispatch(
          toastMessageActions.showToast({
            message: data.title,
            type: toastTypes.DANGER,
          })
        );

        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const createOrganizationalContactAlert =
  (data: IOrganizationAlertData) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showEditorLoading(dispatch);

        const response = await axiosClient({
          method: "post",
          url: "api/Organization/CreateContactDetailsAlertSettings",
          data: `${JSON.stringify(data)}`,
        });
        const status = response.status;

        handleCreateServiceResponse(
          dispatch,
          organizationContactsAction,
          status,
          response.data
        );

        hideEditorLoading(dispatch);
        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideEditorLoading(dispatch);
      }
    });
  };
