import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';

import { IFormSelectControlProps } from 'helpers/uiInterfaces/form-controls';

import SingleSelect from '@common/single-select/SingleSelect';

const FormSelectInputControl: React.FC<IFormSelectControlProps> = (props) => {

  const {
    label,
    name,
    placeholder,
    required,
    error,
    disabled,
    value,
    data,
    disableDefaultSelectOption,
    defaultOptionDataset,
    dontShowArrow,
    onChange,
    onBlur,
    customOnFocus,
  } = props;

  const [_data, _setData] = useState(data);

  useEffect(() => {
    _setData(data);
  }, [data]);

  function handleFormSelectControl(item: any) {
    let _value = '';

    if (item.value === 0) {
      _value = item.value;
    } else if (item && item.value !== null) {
      _value = String(item.value);
    }

    onChange(name, _value, item);
  }


  function renderErrorMessage() {

    if (!error) {
      return;
    }

    return <label className='error-message'>{error}</label>;

  }

  function renderAsterisk() {

    if (!required) {
      return;
    }

    return <FontAwesomeIcon icon={faAsterisk} className='required-asterisk' />;
  }

  let controlValue = value;

  if (value === 0) {
    controlValue = '';
  }

  const singleSelectControlAttributes = {
    data: _data,
    value: String(controlValue),
    placeholder,
    disabled,
    error,
    disableDefaultSelectOption,
    defaultOptionDataset,
    dontShowArrow,
    onSelect: handleFormSelectControl,
    customOnFocus: customOnFocus,
  };

  return (
    <div className="form-group">
      <div className="lbl">
        {label ? <label className='d-flex align-center'>{renderAsterisk()} {label}</label> : ''}
      </div>
      <SingleSelect {...singleSelectControlAttributes} />
      {renderErrorMessage()}
    </div>
  );

};

export default FormSelectInputControl;