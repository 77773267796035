import React from "react";

import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IDashboardInitialAndReCredSelectedFiltersProps } from "@helpers/uiInterfaces/employeeFiles";

import { getCheckboxSelectButtonConfigurations } from "@common/checkbox-select/utilities";

const DashboardInitialAndReCredSelectedFilters: React.FC<
  IDashboardInitialAndReCredSelectedFiltersProps
> = (props) => {
  const { filters, onFilterChange } = props;

  function handleFilterChange(name: string) {
    let data: any = { ...filters };
    if (name === "employeeType" || name === "employeeStatus") {
      data[name] = "All";
    } else if (name === "department" || name === "locations") {
      data[name] = [];
    } else {
      data[name] = "";
    }
    onFilterChange(data);
  }

  function renderRemoveFilterControl(name: string) {
    const removeFilterControlAttributes = {
      icon: faCircleXmark,
      className: "remove-filter-control",
      onClick() {
        handleFilterChange(name);
      },
    };

    return <FontAwesomeIcon {...removeFilterControlAttributes} />;
  }

  function renderFilterChip(label: string, name: string, value: string) {
    const filterChipLabelAttributes = {
      title: `${label}: ${value}`,
    };

    return (
      <>
        {value !== "All" && (
          <div className="filter-chip">
            <label {...filterChipLabelAttributes}>
              {label}: {value}
            </label>
            {renderRemoveFilterControl(name)}
          </div>
        )}
      </>
    );
  }

  function renderSearchNameFilter() {
    if (filters.search.trim() === "") {
      return;
    }

    return renderFilterChip("Name", "search", filters.search);
  }

  function renderEmployeeTypeFilter() {
    if (!filters.employeeType) {
      return;
    }

    let employeeTypeValue = "All";

    if (filters.employeeType === "1") {
      employeeTypeValue = "Active";
    } else if (filters.employeeType === "0") {
      employeeTypeValue = "Inactive";
    }

    return renderFilterChip("Provider Type", "employeeType", employeeTypeValue);
  }

  function renderEmployeeStatusFilter() {
    if (!filters.employeeStatus) {
      return;
    }

    return renderFilterChip(
      "Provider Status",
      "employeeStatus",
      filters.employeeStatus
    );
  }

  function renderLocationsFilter() {
    if (!filters.locations) {
      return;
    }

    const { selectedItemsCount, totalItemsCount } =
      getCheckboxSelectButtonConfigurations(filters.locations);

    let locationsFilterLabel = "All";

    if (selectedItemsCount !== totalItemsCount) {
      locationsFilterLabel = `${selectedItemsCount} of ${totalItemsCount} selected`;
    }

    return renderFilterChip("Locations", "locations", locationsFilterLabel);
  }

  function renderDepartmentFilter() {
    if (!filters.department) {
      return;
    }

    const { selectedItemsCount, totalItemsCount } =
      getCheckboxSelectButtonConfigurations(filters.department);

    let departmentsFilterLabel = "All";

    if (selectedItemsCount !== totalItemsCount) {
      departmentsFilterLabel = `${selectedItemsCount} of ${totalItemsCount} selected`;
    }

    return renderFilterChip(
      "Departments",
      "department",
      departmentsFilterLabel
    );
  }

  return (
    <div className="selected-filters">
      {renderEmployeeTypeFilter()}
      {renderLocationsFilter()}
      {renderEmployeeStatusFilter()}
      {renderDepartmentFilter()}
      {renderSearchNameFilter()}
    </div>
  );
};

export default DashboardInitialAndReCredSelectedFilters;
