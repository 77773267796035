import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICredJobStatus } from "@helpers/interfaces/trackingReport";

const initialState = {
  jobCredStatus: [] as Array<ICredJobStatus>,
};

const trackingReportSlice = createSlice({
  name: "trackingReport",
  initialState,
  reducers: {
    updateJobCredStatus: (
      state,
      action: PayloadAction<Array<ICredJobStatus>>
    ) => {
      state.jobCredStatus = action.payload;
    },
  },
});

export const trackingReportActions = trackingReportSlice.actions;
export default trackingReportSlice.reducer;
