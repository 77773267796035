import {
  ICredReportQueModel,
  IEnhancedCredReportData,
} from "@helpers/interfaces/common";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  enhancedCredReportData: {} as IEnhancedCredReportData,
  eCredQueAnsdata: [] as Array<ICredReportQueModel>,
  error: null as any,
  loading: false,
};

const common = createSlice({
  name: "common",
  initialState,
  reducers: {
    updateEnhancedCredReportData: (
      state,
      action: PayloadAction<IEnhancedCredReportData>
    ) => {
      state.enhancedCredReportData = action.payload;
    },

    updateECREDReportQuestionAnswersData: (
      state,
      action: PayloadAction<Array<ICredReportQueModel>>
    ) => {
      state.eCredQueAnsdata = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.enhancedCredReportData = {} as IEnhancedCredReportData;
      state.eCredQueAnsdata = [] as Array<ICredReportQueModel>;
    },
  },
});

export const commonActions = common.actions;
export default common.reducer;
