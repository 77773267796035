import React from "react";

import { IFormTextareaInputControlProps } from "helpers/uiInterfaces/form-controls";

const FormTextareaInputControl: React.FC<IFormTextareaInputControlProps> = (
  props
) => {
  const { label, name, value, rows, onChange, onFocus } = props;

  const textareaControlAttributes = {
    name,
    value: value ? value : "",
    rows: rows ? rows : 3,
    onChange,
    onFocus: onFocus,
  };

  return (
    <div className="form-group">
      <div className="lbl">
        <label>{label}</label>
      </div>
      <textarea {...textareaControlAttributes} />
    </div>
  );
};

export default FormTextareaInputControl;
