import { IUserRecordModel } from "@helpers/interfaces/authentication/Authentication";
import {
  IPerProf_FederalDEACDSModel,
  IPerProf_GeneralInfoModel,
  IPerProf_HomeAddressModel,
  IPerProf_OptionalInfoModel,
  IPerProf_OtherIdsModel,
  IPerProf_PersonalInfoModel,
  IPerProf_StateLicenseModel,
} from "@helpers/interfaces/credentialProfile/PerProfInfoTab";

export function setDefaultPersonalInfo(
  userRecord: IUserRecordModel
): IPerProf_PersonalInfoModel {
  const data = {
    empCredId: 0,
    userId: userRecord.id,
    orgId: userRecord.organizationId,
    hrmrUserId: userRecord.hrmrUserId,
    providerCode: "",
    doYouPractice: false,
    providerClassification: "",
    titleCredential: 0,
    titleName: "",
    lastName: "",
    suffix: "",
    firstName: "",
    middleName: "",
    usedOtherName: false,
    otherLastName: "",
    otherSuffix: "",
    otherFirstName: "",
    otherMiddleName: "",
    dateStartOtherName: null,
    dateStopOtherName: null,
  };

  return data;
}

export function setDefaultGeneralInfo(
  userRecord: IUserRecordModel
): IPerProf_GeneralInfoModel {
  const data = {
    hrmrUserId: userRecord.hrmrUserId,
    gender: "",
    dob: null,
    dobCity: 0,
    dobState: 0,
    dobCountry: 0,
    ssn: "",
    fnin: "",
    fninCountry: 0,
  };

  return data;
}

export function setDefaultHomeAddressData(
  userRecord: IUserRecordModel
): IPerProf_HomeAddressModel {
  const data = {
    hrmrUserId: userRecord.hrmrUserId,
    number: "",
    street: "",
    aptNumber: "",
    city: 0,
    county: 0,
    state: 0,
    zip: "",
    telephone: "",
    email: "",
    fax: "",
    methodContactEmail: false,
    methodConctactFax: false,
  };

  return data;
}

export function setDefaultFederalDEACDSInfo(
  _userRecord: IUserRecordModel
): IPerProf_FederalDEACDSModel {
  const data = {
    id: 0,
    hrmrUserId: _userRecord.hrmrUserId,
    professionalsIdType: "",
    number: "",
    issueState: 0,
    issueDate: null,
    expirationDate: null,
    unlimitedPrivileges: true,
    explain: "",
  };

  return data;
}

export function setDefaultStateLicenseInfo(
  _userRecord: IUserRecordModel
): IPerProf_StateLicenseModel {
  const data = {
    stateLicenseId: 0,
    hrmrUserId: _userRecord.hrmrUserId,
    stateLicenseNumber: "",
    stateLicenseDOI: null,
    stateLicenseIssueState: 0,
    stateLicenseExpDate: null,
    stateLicenseYesNo: false,
    stateLicenseCode: "",
    stateLicenseType: "",
    fieldCode: "",
    hrsaCredId: 0,
    isDelete: 0,
    isWasLicenseRestricted: false,
    seqNo: 0,
  };

  return data;
}

export function setDefaultOtherIDInfo(
  userRecord: IUserRecordModel
): IPerProf_OtherIdsModel {
  const data = {
    hrmrUserId: userRecord.hrmrUserId,
    driverLicenseNumber: "",
    driverLicenseIssueDate: null,
    driverLicenseState: 0,
    driverLicenseExpiration: null,
    npiNumber: "",
    pecosUserName: "",
    pecosPassword: "",
    caqhId: "",
    caqhStatus: "",
    caqhLastAttestationDate: null,
    caqhReattestationDate: null,
    caqhUserName: "",
    caqhPassword: "",
    caqhIsApprove: "",
    batchId: "",
    adaId: "",
    ohpssoUsername: "",
    ohpssoPassword: "",
    ohpssoAttestationDate: null,
    ohpssoReAttestationDate: null,
    otherSoftwareId: "",
  };

  return data;
}

export function setDefaultOptionalInfo(
  userRecord: IUserRecordModel
): IPerProf_OptionalInfoModel {
  const data: IPerProf_OptionalInfoModel = {
    hrmrUserId: userRecord.hrmrUserId,
    medicareProvider: false,
    medicareNumber: "",
    medicareId: "",
    upin: "",
    aypMedicaidProvider: false,
    medicaidNumber: "",
    medicaidState: 0,
    medicaidIssueDate: null,
    medicaidExpiryDate: null,
    medicaidUserName: "",
    medicaidPassword: "",
    usmleNumber: "",
    workerCompensationNumber: "",
    ecfmgNumber: "",
    ecfmgIssueDate: null,
    ecfmgExpDate: null,
    languagesCode: null,
    patientAgeGroup: "",
    isLimitedTimeWork: false,
    isCulturalSensitive: true,
    isLGBTQSupportive: true,
    limitedTimeWorkEndDate: null,
  };

  return data;
}
