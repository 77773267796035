import React from "react";

import moment from "moment";

import { IPaginatedTableProps } from "@helpers/uiInterfaces/common";
import applicationTableColumnsTypes from "@constants/application-table-columns-types";

import PaginatedTableFooter from "./PaginatedTableFooter";

import styles from "./PaginatedTable.module.scss";
import { isDateInPastOneMonth } from "@utilities/index";

const PaginatedTable: React.FC<IPaginatedTableProps> = (props) => {
  const {
    columns,
    rows,
    actionElement,
    currentPage,
    totalRecord,
    rowsPerPage,
    onPageLimitChange,
    onPageChange,
    boldKeys,
    linkToCall,
    rowBold,
  } = props;

  const [sortColumn, setSortColumn] = React.useState("");
  const [sortDirection, setSortDirection] = React.useState("");

  const totalPage = Math.ceil(totalRecord / rowsPerPage);

  function renderTableHeaderActionCell() {
    if (typeof actionElement === "undefined") {
      return;
    }

    return <th>Actions</th>;
  }

  function renderTableHeaderCell(column: any, index: number) {
    const isSortable = column.sortable !== false;

    const handleSortClick = () => {
      if (isSortable) {
        if (sortColumn === column.id) {
          // Reverse the sort direction if the same column is clicked again
          setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
          // Sort the clicked column in ascending order by default
          setSortColumn(column.id);
          setSortDirection("asc");
        }
      }
    };

    const sortIndicator =
      isSortable && sortColumn === column.id ? (
        sortDirection === "asc" ? (
          <span>&uarr;</span>
        ) : (
          <span>&darr;</span>
        )
      ) : null;

    if (typeof column.renderCell === "undefined") {
      return (
        <th key={index} onClick={handleSortClick}>
          {column.label} {sortIndicator}
        </th>
      );
    }

    return (
      <th key={index} onClick={handleSortClick}>
        {column.renderCell} {sortIndicator}
      </th>
    );
  }

  function renderTableHeader() {
    return (
      <thead>
        <tr>
          {columns.map((column, index) => renderTableHeaderCell(column, index))}
          {renderTableHeaderActionCell()}
        </tr>
      </thead>
    );
  }

  function renderTableRowActionCell(row: any, index: number) {
    if (typeof actionElement === "undefined") {
      return;
    }

    return <td>{actionElement(row, index)}</td>;
  }

  function renderYesNoLabel(value: string | boolean) {
    if (value && (value === true || value === "Yes")) {
      return <label className={styles.yesLabel}>Yes</label>;
    }

    return <label className={styles.noLabel}>No</label>;
  }

  function renderMultipleValuesCell(value: Array<any>, columnType?: string) {
    return (
      <div className={styles.multipleValuesCell}>
        {value &&
          value.length > 0 &&
          value.map((item, index) => {
            if (linkToCall && columnType) {
              return (
                <label className={styles.multipleValueLabel} key={index}>
                  {item ? (
                    <a href={`tel:${item}`}>{item}</a>
                  ) : (
                    "**Not available**"
                  )}
                </label>
              );
            } else {
              return (
                <label className={styles.multipleValueLabel} key={index}>
                  {item || "**Not available**"}
                </label>
              );
            }
          })}
      </div>
    );
  }

  function renderTableRowCell(
    row: any,
    column: any,
    index: number,
    rowIndex: number
  ) {
    const value = row[column.id];
    const isDateUnderOneMonth = isDateInPastOneMonth(row.createdDate);
    if (column.type === applicationTableColumnsTypes.BOLD_STRING) {
      if (boldKeys && boldKeys.length > 0) {
        let boldTd = <td></td>;
        boldKeys.forEach((key: string) => {
          if (column.id === key) {
            if (isDateUnderOneMonth) {
              boldTd = (
                <td key={index} className={styles.longText}>
                  <b>{value}</b>
                </td>
              );
            } else {
              boldTd = (
                <td key={index} className={styles.longText}>
                  {value}
                </td>
              );
            }
          }
        });
        return boldTd;
      }
    }

    if (column.type === applicationTableColumnsTypes.YESNO) {
      return (
        <td
          key={index}
          className={`${styles.yesNoLabelCell} ${
            rowBold === rowIndex ? styles.boldCss : ""
          }`}
        >
          {renderYesNoLabel(value)}
        </td>
      );
    }

    if (column.type === applicationTableColumnsTypes.DATE) {
      const date = value ? moment(value).format("MM/DD/YYYY") : "--NA--";
      return (
        <td key={index} className={rowBold === rowIndex ? styles.boldCss : ""}>
          {date}
        </td>
      );
    }

    if (column.type === applicationTableColumnsTypes.MULTIPLE_VALUES) {
      return (
        <td
          key={index}
          className={`${styles.longText} ${
            rowBold === rowIndex ? styles.boldCss : ""
          }`}
        >
          {renderMultipleValuesCell(value)}
        </td>
      );
    }

    if (column.type === applicationTableColumnsTypes.MULTIPLE_MOBILE_VALUE) {
      if (value) {
        const phoneNumberArray = value.split(",");
        const uniquePhoneNumberArray = phoneNumberArray.filter(
          (value: string, index: number, self: string) => {
            return self.indexOf(value) === index;
          }
        );

        return (
          <td
            key={index}
            className={`${styles.longText} ${
              rowBold === rowIndex ? styles.boldCss : ""
            }`}
          >
            {renderMultipleValuesCell(uniquePhoneNumberArray, column.type)}
          </td>
        );
      } else {
        return (
          <td
            key={index}
            className={`${styles.longText} ${
              rowBold === rowIndex ? styles.boldCss : ""
            }`}
          >
            {renderMultipleValuesCell([""])}
          </td>
        );
      }
    }

    return (
      <td
        key={index}
        className={`${
          column.id === "renderExpiryDateAndSendEmailSection"
            ? ""
            : styles.longText
        } ${rowBold === rowIndex ? styles.boldCss : ""}`}
      >
        {value ? value : "--NA--"}
      </td>
    );
  }

  function renderTableBodyRow(row: any, index: number) {
    return (
      <tr key={index}>
        {columns.map((column, _index) =>
          renderTableRowCell(row, column, _index, index)
        )}
        {renderTableRowActionCell(row, index)}
      </tr>
    );
  }

  const sortedRows = React.useMemo(() => {
    if (sortColumn) {
      const sorted = [...rows];
      sorted.sort((a, b) => {
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];

        // Handle different value types for sorting
        if (typeof valueA === "string" && typeof valueB === "string") {
          return valueA.localeCompare(valueB);
        }
        if (typeof valueA === "number" && typeof valueB === "number") {
          return valueA - valueB;
        }

        return 0;
      });

      if (sortDirection === "desc") {
        sorted.reverse();
      }

      return sorted;
    }

    return rows;
  }, [rows, sortColumn, sortDirection]);

  function renderTableBody() {
    return (
      <tbody>
        {sortedRows.map((row, index) => renderTableBodyRow(row, index))}
      </tbody>
    );
  }

  const paginatedTableFooterAttributes = {
    rowsPerPage,
    currentPage,
    totalPage,
    onPageChange,
    onPageLimitChange,
  };

  return (
    <div className={styles.paginatedTableMain}>
      <table className={styles.paginatedTable}>
        {renderTableHeader()}
        {renderTableBody()}
      </table>

      <PaginatedTableFooter {...paginatedTableFooterAttributes} />
    </div>
  );
};

export default PaginatedTable;
