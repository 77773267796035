import React from "react";

import ReactInputMask from "react-input-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

import { IFormInputControlProps } from "helpers/uiInterfaces/form-controls";

import SecureInputField from "@common/secure-input-field/SecureInputField";

type TFormInputTextControlReference = HTMLInputElement;

const FormInputTextControl = React.forwardRef<
  TFormInputTextControlReference,
  IFormInputControlProps
>((props, ref) => {
  const {
    label,
    name,
    type,
    disabled,
    secure,
    masked,
    maskValue,
    placeholder,
    value,
    required,
    error,
    maxlength,
    removeMinHeight,
    onChange,
    onBlur,
    onFocus,
  } = props;

  function renderErrorMessage() {
    if (!error) {
      return;
    }

    return <label className="error-message">{error}</label>;
  }

  function renderAsterisk() {
    if (!required) {
      return;
    }

    return <FontAwesomeIcon icon={faAsterisk} className="required-asterisk" />;
  }

  function renderSecureInputField() {
    const secureInputFieldControlAttributes = {
      name,
      error,
      value: value ? value : "",
      disabled,
      onChange,
      onBlur,
      onFocus,
    };

    return <SecureInputField {...secureInputFieldControlAttributes} />;
  }

  function renderMaskedInputField() {
    const reactInputMaskControlAttributes = {
      placeholder: placeholder ? placeholder : "",
      mask: maskValue ? maskValue : "",
      className: error ? "error" : "",
      disabled: disabled ? disabled : false,
      value,
      name,
      onChange,
      onFocus: onFocus,
    };

    return <ReactInputMask {...reactInputMaskControlAttributes} />;
  }

  function renderInputControl() {
    if (secure === true) {
      return renderSecureInputField();
    }

    if (masked === true) {
      return renderMaskedInputField();
    }

    const inputControlAttributes = {
      type,
      name,
      ref,
      className: error ? "error" : "",
      placeholder: placeholder ? placeholder : "",
      maxLength: maxlength ? maxlength : 250,
      value,
      disabled: disabled ? disabled : false,
      onChange,
      onBlur,
      onFocus,
    };

    return <input {...inputControlAttributes} />;
  }

  function renderLabel() {
    return (
      <div className="lbl">
        <label className="d-flex align-center">
          {renderAsterisk()} {label}
        </label>
      </div>
    );
  }

  return (
    <div className="form-group" style={removeMinHeight ? { minHeight: "0px" } : {}}>
      {renderLabel()}
      {renderInputControl()}
      {renderErrorMessage()}
    </div>
  );
});

export default FormInputTextControl;
