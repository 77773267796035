import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IEmpPayerPrivilegingList,
  IPrivilegingModel,
} from "@helpers/interfaces/employeeFiles";
import { IMastersDataModel } from "@helpers/interfaces/masters/Masters";

const initialState = {
  employeePayerPrivilegingList: [] as Array<IEmpPayerPrivilegingList>,
  privilegingPayerProviderData: {} as IPrivilegingModel,
  privilegingCategoryList: [] as Array<IMastersDataModel>,
  error: null as any,
  loading: false,
};

const editEmployeesPayerSlice = createSlice({
  name: "addEditEmployees",
  initialState,
  reducers: {
    updateEmployeePayerPrivilegingList: (
      state,
      action: PayloadAction<Array<IEmpPayerPrivilegingList>>
    ) => {
      state.employeePayerPrivilegingList = action.payload;
    },

    updatePrivilegingPayerProviderById: (
      state,
      action: PayloadAction<IPrivilegingModel>
    ) => {
      state.privilegingPayerProviderData = action.payload;
    },

    updatePrivilegingCategories: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.privilegingCategoryList = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetPrivilegingData: (state) => {
      state.privilegingPayerProviderData = {} as IPrivilegingModel;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.employeePayerPrivilegingList =
        [] as Array<IEmpPayerPrivilegingList>;
    },
  },
});

export const editEmployeesPayerActions = editEmployeesPayerSlice.actions;
export default editEmployeesPayerSlice.reducer;
