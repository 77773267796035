import applicationTableColumnsTypes from "@constants/application-table-columns-types";

import { IApplicationTableColumn } from "@helpers/uiInterfaces/common";

const credentialingTrackingListLogsColumns: Array<IApplicationTableColumn> = [
  {
    id: "jobNo",
    label: "Instance",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "organizationNames",
    label: "Organization Name",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "credTypeValue",
    label: "Cred Type",
    type: applicationTableColumnsTypes.CUSTOM,
  },
  {
    id: "processingTypeValue",
    label: "Processing Type",
    type: applicationTableColumnsTypes.CUSTOM,
  },
  {
    id: "credJobStatusValue",
    label: "Instance Status",
    type: applicationTableColumnsTypes.CUSTOM,
  },
  {
    id: "associatedCredentialerName",
    label: "Credentialer Name",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "createdDate",
    label: "Created Date",
    type: applicationTableColumnsTypes.DATE,
  },
  {
    id: "workflowStartDate",
    label: "From Date",
    type: applicationTableColumnsTypes.DATE,
  },
  {
    id: "workflowEndDate",
    label: "To Date",
    type: applicationTableColumnsTypes.DATE,
  },
  {
    id: "comment",
    label: "Comments",
    type: applicationTableColumnsTypes.STRING,
  },
];

export default credentialingTrackingListLogsColumns;
