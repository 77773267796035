const propertyTypes = {
  ESTIMATED_WAIT_TIME_ID: "EstimatedWaitTimeId",
  OPIOID_EXPERIENCE_LEVELS: "OpioidExperienceLevels",
  PRIVILEGING_STATUS: "PrivilegingStatus",
  VISIT_TYPE_IDS: "VisitTypeIDs",
  DIRECTORIES: "Directories",
  BOARD_STATUS: "BoardStatus",
  TERMINATION_REASONS: "TerminationReasons",
};

export default propertyTypes;
