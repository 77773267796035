import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IServiceSiteModel,
  IServiceSitePaginationModel,
} from "@helpers/interfaces/serviceSites";
import { setDefaultServiceSiteData } from "@helpers/configMiddlewares/serviceSite";
import { IMastersDataModel } from "@helpers/interfaces/masters/Masters";

const initialState = {
  serviceSiteData: {} as IServiceSiteModel,
  serviceSiteDataList: {} as IServiceSitePaginationModel,
  serviceSiteByType: [] as Array<IServiceSiteModel>,
  directoriesForAffiliatedInstitution: [] as Array<IMastersDataModel>,
  error: null as any,
  loading: false,
  editorLoading: false,
};

const serviceSite = createSlice({
  name: "serviceSite",
  initialState,
  reducers: {
    updateServiceSiteData: (
      state,
      action: PayloadAction<IServiceSiteModel>
    ) => {
      state.serviceSiteData = action.payload;
    },

    updateServiceSiteDataList: (
      state,
      action: PayloadAction<IServiceSitePaginationModel>
    ) => {
      state.serviceSiteDataList = action.payload;
    },

    updateServiceSiteByType: (
      state,
      action: PayloadAction<Array<IServiceSiteModel>>
    ) => {
      state.serviceSiteByType = action.payload;
    },

    updateDirectoriesForAffiliatedInstitution: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.directoriesForAffiliatedInstitution = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    resetEditorState: (state) => {
      state.editorLoading = false;
      state.error = null;
      state.serviceSiteData = setDefaultServiceSiteData();
      state.directoriesForAffiliatedInstitution = [];
    },
  },
});

export const serviceSiteActions = serviceSite.actions;
export default serviceSite.reducer;
