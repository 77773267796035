import React, { useEffect, useState } from "react";
import TabPanel from "@common/TabPanel";
import { ICredentialingTrackingProps } from "@helpers/uiInterfaces/add-edit-employee";
import styles from "./Edit_CredentialingTracking.module.scss";
import PaginatedTable from "@common/paginated-table/PaginatedTable";
import credentialingTrackingListColumns from "@constants/json-data/table-columns/employee-files/credentialingTrackingListColumns";
import AddEditCredentialingTrackingEditor from "./AddEditCredentialingTrackingEditor";
import { useDispatch } from "react-redux";
import {
  getCredentialingTrackingLogsPageable,
  getCredentialingTrackingPageable,
  getCredTrackingHeaderData,
} from "@services/employeeFiles/EditCredentialingTracking";
import { useAppSelector } from "@hooks/index";
import { parseCredTrackingData, parseCredTrackingLogsData } from "../utilities";
import NoContentSection from "@common/no-content-section/NoContentSection";
import Spinner from "@common/spinner/Spinner";
import { ICredTrackingModel } from "@helpers/interfaces/employeeFiles";
import { getAllJobCredStatus } from "@services/tracking-report";
import CardDisplay from "@common/card/Card";
import moment from "moment";
import credentialingTrackingListLogsColumns from "@constants/json-data/table-columns/employee-files/credentialingTrackingListLogsColumns";

const DEFAULT_PAGE_LIMIT = 10;

const Edit_CredentialingTracking: React.FC<ICredentialingTrackingProps> = (
  props
) => {
  const { data, tabIndex } = props;
  const [
    showEditCredentialingTrackingModel,
    setShowEditCredentialingTrackingModel,
  ] = useState(false);
  const [
    showCredentialingTrackingLogsModel,
    setShowCredentialingTrackingLogsModel,
  ] = useState(false);
  const [paginationState, setPaginationState] = useState({
    page: 1,
    limit: DEFAULT_PAGE_LIMIT,
  });
  const [logsPaginationState, setLogsPaginationState] = useState({
    page: 1,
    limit: DEFAULT_PAGE_LIMIT,
  });
  const [selectedData, setSelectedData] = useState<ICredTrackingModel | null>(
    null
  );
  const credTrackingState = useAppSelector(
    (state) => state.employeeFiles.editCredentialingTracking
  );
  const jobCredStatus = useAppSelector(
    (state) => state.trackingReport.jobCredStatus
  );
  const credHeaderData = useAppSelector(
    (state) =>
      state.employeeFiles.editCredentialingTracking
        .credentialingTrackingHeaderData
  );
  const credTrackingDataList =
    credTrackingState.credentialingTrackingList?.page;
  const credTrackingLogsList =
    credTrackingState.credentialingTrackingLogsList?.page;

  const dispatch = useDispatch<any>();

  useEffect(() => {
    loadCredTrackingList(data.hrmrUserId, 1, DEFAULT_PAGE_LIMIT);
  }, [showEditCredentialingTrackingModel, data.hrmrUserId]);

  async function loadCredTrackingList(
    hrmrUserId: number,
    pageNumber: number,
    pageLimit: number
  ) {
    await dispatch(getCredTrackingHeaderData(hrmrUserId));
    await dispatch(getAllJobCredStatus());
    await dispatch(
      getCredentialingTrackingPageable(hrmrUserId, pageNumber, pageLimit)
    );
    setPaginationState({
      ...paginationState,
      page: pageNumber,
      limit: pageLimit,
    });
  }

  async function loadCredTrackingLogsList(
    hrmrUserId: number,
    credTrackingId: number,
    pageNumber: number,
    pageLimit: number
  ) {
    await dispatch(
      getCredentialingTrackingLogsPageable(
        hrmrUserId,
        credTrackingId,
        pageNumber,
        pageLimit
      )
    );
    setLogsPaginationState({
      ...logsPaginationState,
      page: pageNumber,
      limit: pageLimit,
    });
  }

  const tabPanelAttributes = {
    active: tabIndex === 10 ? true : false,
  };

  function renderTableActionElements(data: ICredTrackingModel) {
    const editControlAttributes = {
      className: "table-link-button",
      disabled: data.isEditEnalble === false,
      onClick() {
        setSelectedData(data);
        setShowEditCredentialingTrackingModel(true);
      },
    };

    const showLogsControlAttributes = {
      className: "table-link-button",
      async onClick() {
        await loadCredTrackingLogsList(
          data.hrmrUserId,
          data.credentialingTrackingId,
          1,
          DEFAULT_PAGE_LIMIT
        );
        setShowCredentialingTrackingLogsModel(true);
      },
    };

    return (
      <div className={styles.actionElements}>
        <button {...editControlAttributes}>Edit</button> |
        <button {...showLogsControlAttributes}>View Log</button>
      </div>
    );
  }

  const renderContent = () => {
    if (!credTrackingDataList || credTrackingState.loading) {
      return <Spinner />;
    }

    if (credTrackingDataList.length === 0) {
      const noContentSectionAttributes = {
        onClick() {
          setShowEditCredentialingTrackingModel(true);
        },
      };
      return <NoContentSection {...noContentSectionAttributes} />;
    }

    const createCredJobControlAttributes = {
      className: `btn btnorg w240 ${styles.createCredBtn}`,
      disabled:
        credTrackingDataList.filter((data) => data.isOpenInstance === true)
          .length > 0,
      onClick() {
        setShowEditCredentialingTrackingModel(true);
      },
    };

    const credTrackingList = parseCredTrackingData(
      credTrackingDataList,
      jobCredStatus
    );

    const paginatedTableAttributes = {
      columns: credentialingTrackingListColumns,
      rows: credTrackingList,
      actionElement: renderTableActionElements,
      currentPage: paginationState.page,
      totalRecord:
        credTrackingState.credentialingTrackingList?.paging?.totalItems,
      rowsPerPage: paginationState.limit,
      onPageLimitChange(limit: number) {
        loadCredTrackingList(data.hrmrUserId, 1, limit);
      },
      onPageChange(page: number) {
        loadCredTrackingList(data.hrmrUserId, page, paginationState.limit);
      },
      rowBold: credTrackingList.findIndex(
        (data) => data.isCompleted === false
      ),
    };

    const credStartDate = credHeaderData.workflowStartDate
      ? moment(credHeaderData.workflowStartDate).format("MM/DD/YYYY")
      : "";
    const credEndDate = credHeaderData.workflowEndDate
      ? moment(credHeaderData.workflowEndDate).format("MM/DD/YYYY")
      : "";
    const dateReportedToClient = credHeaderData.dateReportToClient
      ? moment(credHeaderData.dateReportToClient).format("MM/DD/YYYY")
      : "";

    const cardDisplayProps = {
      title: <div></div>,
      subtitle: <div></div>,
      data: (
        <table className={styles.credentialTable}>
          <tr>
            <td className={styles.credentialName}>Credentialing Start Date:</td>

            <td className={styles.credentialName}>
              Credentialing Expiration Date:
            </td>

            <td className={styles.credentialName}>Cred Type:</td>
            <td className={styles.credentialName}>Date Reported to Client:</td>
          </tr>
          <tr>
            <td className={styles.credentialValue}>{credStartDate}</td>
            <td className={styles.credentialValue}>{credEndDate}</td>
            <td className={styles.credentialValue}>
              {credHeaderData.providerStatus}
            </td>
            <td className={styles.credentialValue}>{dateReportedToClient}</td>
          </tr>
        </table>
      ),
    };

    return (
      <div
        id={styles.credentialingTrackingMain}
        className="d-flex flex-column m-3"
      >
        <CardDisplay {...cardDisplayProps} />
        <div className={styles.secondPartContent}>
          <button {...createCredJobControlAttributes}>
            Create Cred Instance
          </button>
          <PaginatedTable {...paginatedTableAttributes} />
        </div>
      </div>
    );
  };

  const renderCredTrackingLogsContent = () => {
    if (!credTrackingLogsList || credTrackingState.loading) {
      return <Spinner />;
    }

    if (credTrackingLogsList.length === 0) {
      return <NoContentSection />;
    }

    const credTrackingLogList = parseCredTrackingLogsData(
      credTrackingLogsList,
      jobCredStatus
    );

    const paginatedTableAttributes = {
      columns: credentialingTrackingListLogsColumns,
      rows: credTrackingLogList,
      currentPage: logsPaginationState.page,
      totalRecord:
        credTrackingState.credentialingTrackingLogsList?.paging?.totalItems,
      rowsPerPage: logsPaginationState.limit,
      onPageLimitChange(limit: number) {
        loadCredTrackingLogsList(
          data.hrmrUserId,
          data.credentialingTrackingId,
          1,
          limit
        );
      },
      onPageChange(page: number) {
        loadCredTrackingLogsList(
          data.hrmrUserId,
          data.credentialingTrackingId,
          page,
          paginationState.limit
        );
      },
    };

    const backButtonControlAttributes = {
      className: `btn btnorg w100 float-end mt-3"`,
      onClick() {
        setShowCredentialingTrackingLogsModel(false);
      },
    };

    return (
      <div
        id={styles.credentialingTrackingMain}
        className="d-flex flex-column m-3"
      >
        <div className={styles.secondPartContent}>
          <PaginatedTable {...paginatedTableAttributes} />
          <button {...backButtonControlAttributes}>Back</button>
        </div>
      </div>
    );
  };

  const renderEditCredentialingTrackingModel = () => {
    if (showEditCredentialingTrackingModel === false) {
      return;
    }

    const addEditCredentialingTrackingEditorAttributes = {
      data: selectedData,
      employeeData: data,
      jobId: credHeaderData.jobNo,
      setShowEditCredentialingTrackingModel:
        setShowEditCredentialingTrackingModel,
      updateSelectedData(_data: any) {
        setSelectedData(_data);
      },
    };

    return (
      <AddEditCredentialingTrackingEditor
        {...addEditCredentialingTrackingEditorAttributes}
      />
    );
  };

  const renderContentBasedOnState = () => {
    if (showEditCredentialingTrackingModel) {
      return renderEditCredentialingTrackingModel();
    }

    if (showCredentialingTrackingLogsModel) {
      return renderCredTrackingLogsContent();
    }

    return renderContent();
  };

  return (
    <TabPanel {...tabPanelAttributes}>{renderContentBasedOnState()}</TabPanel>
  );
};

export default Edit_CredentialingTracking;
