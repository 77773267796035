import React from 'react';

import { ITabPanelProps } from 'helpers/uiInterfaces/common';

const TabPanel: React.FC<ITabPanelProps> = (props) => {

  const { active } = props;

  let tabPanelClassName = 'tab-pane tab_active';

  if (active === true) {
    tabPanelClassName += ' active';
  }

  const tabPanelAttributes = {
    className: tabPanelClassName,
    role: 'tabpanel'
  };

  return (
    <div {...tabPanelAttributes}>
      <div className="panel panel-default">
        <div className="panel-body">
          {props.children}
        </div>
      </div>
    </div>
  );

};

export default TabPanel;