import toastTypes from "@constants/toast-types";
import { toastMessageActions } from "@features/ui/ToastMessage";
import axiosClient from "@store/axiosClient";
import {
  handleCreateServiceResponse,
  handleServerInteractionError,
} from "@utilities/services";
import { credentialingTrackingActions } from "@features/employeeFiles/EditCredentialingTracking";

function showLoading(dispatch: any) {
  dispatch(credentialingTrackingActions.updateLoading(true));
}

function hideLoading(dispatch: any) {
  dispatch(credentialingTrackingActions.updateLoading(false));
}

function showEditorLoading(dispatch: any) {
  dispatch(credentialingTrackingActions.updateEditorLoading(true));
}

function hideEditorLoading(dispatch: any) {
  dispatch(credentialingTrackingActions.updateEditorLoading(false));
}

export const getCredentialingTrackingPageable =
  (hrmrUserId: number, pageNumber: number, pageSize: number) =>
  async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);

        let url = `api/CredentialingTracking/GetCredTrackingPageable?HRMRUserId=${hrmrUserId}&PageNumber=${pageNumber}&PageSize=${pageSize}`;

        const response = await axiosClient.get(url);
        const data = response.data;
        const status = response.status;

        if (status === 200) {
          dispatch(
            credentialingTrackingActions.updateCredentialingTrackingList(data)
          );

          resolve(response);
          return hideLoading(dispatch);
        }

        dispatch(
          toastMessageActions.showToast({
            message: data,
            type: toastTypes.DANGER,
          })
        );

        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const getCredTrackingData =
  (credTrackingId: number) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      showEditorLoading(dispatch);

      try {
        const response = await axiosClient.get(
          "api/CredentialingTracking/GetCredTrackingById?CredentialingTrackingId=" +
            credTrackingId
        );
        const data = response.data;
        const status = response.status;

        if (status === 200) {
          dispatch(
            credentialingTrackingActions.updateCredentialingTrackingData(
              response.data
            )
          );
          resolve(response);
        } else {
          dispatch(
            toastMessageActions.showToast({
              message: data.title,
              type: toastTypes.DANGER,
            })
          );
        }

        hideEditorLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideEditorLoading(dispatch);
      }
    });
  };

export const upsertCredTracking = (data: any) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      showEditorLoading(dispatch);
      const response = await axiosClient.post(
        "api/CredentialingTracking/AddEditCredTracking",
        data
      );
      const status = response.status;

      handleCreateServiceResponse(
        dispatch,
        credentialingTrackingActions,
        status,
        response.data
      );

      hideEditorLoading(dispatch);
      resolve(response);
    } catch (error) {
      handleServerInteractionError(dispatch);
      hideEditorLoading(dispatch);
    }
  });
};

export const getCredentialingTrackingLogsPageable =
  (
    hrmrUserId: number,
    credTrackingId: number,
    pageNumber: number,
    pageSize: number
  ) =>
  async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);

        let url = `api/CredentialingTracking/GetCredTrackingLogsPageable?HRMRUserId=${hrmrUserId}&CredentialingTrackingId=${credTrackingId}&PageNumber=${pageNumber}&PageSize=${pageSize}`;

        const response = await axiosClient.get(url);
        const data = response.data;
        const status = response.status;

        if (status === 200) {
          dispatch(
            credentialingTrackingActions.updateCredentialingTrackingLogsList(
              data
            )
          );

          resolve(response);
          return hideLoading(dispatch);
        }

        dispatch(
          toastMessageActions.showToast({
            message: data,
            type: toastTypes.DANGER,
          })
        );

        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const getCredTrackingHeaderData =
  (hrmrUserId: number) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      showEditorLoading(dispatch);

      try {
        const response = await axiosClient.get(
          "api/CredentialingTracking/GetCredTrackingHeader?HRMRUserId=" +
            hrmrUserId
        );
        const data = response.data;
        const status = response.status;

        if (status === 200) {
          dispatch(
            credentialingTrackingActions.updateCredentialingTrackingHeaderData(
              response.data
            )
          );
          resolve(response);
        } else {
          dispatch(
            toastMessageActions.showToast({
              message: data.title,
              type: toastTypes.DANGER,
            })
          );
        }

        hideEditorLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideEditorLoading(dispatch);
      }
    });
  };
