import messages from "@constants/messages";
import toastTypes from "@constants/toast-types";
import { commonInterfacesActions } from "@features/ui/CommonInterfaces";

import { credentialProfileTabsActions } from "@features/ui/CredentialProfileTabs";
import { toastMessageActions } from "@features/ui/ToastMessage";

import { getCredentialProfileTabs } from "@services/credentialing-profile";

export function handleServerInteractionError(dispatch: any) {
  // dispatch(toastMessageActions.showToast({
  //   message: messages.somethingWentWrong,
  //   type: toastTypes.DANGER
  // }));

  dispatch(
    commonInterfacesActions.updateDisplayAuthorizationFailedDialog(true)
  );
}

export function handleCreateServiceResponse(
  dispatch: any,
  action: any,
  status: number,
  data: any,
  message?: string
) {
  if (status === 200) {
    if (message) {
      dispatch(
        toastMessageActions.showToast({
          message: message,
          type: toastTypes.SUCCESS,
        })
      );
    } else {
      dispatch(
        toastMessageActions.showToast({
          message: messages.dataSuccessfullySaved,
          type: toastTypes.SUCCESS,
        })
      );
    }
  } else if (status === 400) {
    dispatch(
      toastMessageActions.showToast({
        message: messages.validationsErrorOccurred,
        type: toastTypes.DANGER,
      })
    );

    dispatch(action.updateError(data));
  } else {
    dispatch(
      toastMessageActions.showToast({
        message: data.title,
        type: toastTypes.DANGER,
      })
    );
  }
}

export function handleDeleteServiceResponse(
  dispatch: any,
  status: number,
  data: any
) {
  if (status === 200) {
    dispatch(
      toastMessageActions.showToast({
        message: messages.dataSuccessfullyDeleted,
        type: toastTypes.SUCCESS,
      })
    );
  } else {
    dispatch(
      toastMessageActions.showToast({
        message: data.title,
        type: toastTypes.DANGER,
      })
    );
  }
}

export function handleOtherServiceResponses(
  dispatch: any,
  status: number,
  data: any,
  message: string
) {
  if (status === 200) {
    dispatch(
      toastMessageActions.showToast({
        message,
        type: toastTypes.SUCCESS,
      })
    );
  } else {
    dispatch(
      toastMessageActions.showToast({
        message: data.title,
        type: toastTypes.DANGER,
      })
    );
  }
}

export function refetchCredentialProfileTabs(
  dispatch: any,
  hrmrUserId: number
) {
  dispatch(credentialProfileTabsActions.updateFirstManipulation(false));
  dispatch(getCredentialProfileTabs(hrmrUserId));
}
