import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IServiceSiteDirectoriesModel } from "@helpers/interfaces/serviceSites/Directories";

const initialState = {
  servicesSiteDirectoriesData: [] as Array<IServiceSiteDirectoriesModel>,
  error: null as any,
  loading: false,
  editorLoading: false,
};

const servicesDirectoriesSlice = createSlice({
  name: "servicesDirectories",
  initialState,
  reducers: {
    updateServiceSiteDirectoriesLinkData: (
      state,
      action: PayloadAction<Array<IServiceSiteDirectoriesModel>>
    ) => {
      state.servicesSiteDirectoriesData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.servicesSiteDirectoriesData =
        [] as Array<IServiceSiteDirectoriesModel>;
    },
  },
});

export const serviceSiteDirectoriesActions = servicesDirectoriesSlice.actions;
export default servicesDirectoriesSlice.reducer;
