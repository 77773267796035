import toastTypes from "@constants/toast-types";
import { trackingReportActions } from "@features/tracking-report";
import { toastMessageActions } from "@features/ui/ToastMessage";
import axiosClient from "@store/axiosClient";
import { handleServerInteractionError } from "@utilities/services";

export const getAllJobCredStatus = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosClient.get(`/api/Report/GetAllJobCredStatus`);
      const data = response.data;
      if (response.status === 200) {
        dispatch(trackingReportActions.updateJobCredStatus(data));
      } else {
        dispatch(
          toastMessageActions.showToast({
            message: data.title,
            type: toastTypes.DANGER,
          })
        );
      }
      resolve(response);
    } catch (error) {
      handleServerInteractionError(dispatch);
    }
  });
};
