const employeeEditorTabs = [
  {
    index: 1,
    label: "Add/Edit",
  },
  {
    index: 2,
    label: "Portal",
  },
  {
    index: 3,
    label: "Exclusions",
  },
  {
    index: 4,
    label: "Training",
  },
  {
    index: 5,
    label: "Onboarding",
  },
  {
    index: 6,
    label: "Credentialing",
  },
  {
    index: 7,
    label: "Gaps in Cred",
  },
  {
    index: 8,
    label: "Create File(s)",
  },
  {
    index: 9,
    label: "Finalize",
  },
  {
    index: 10,
    label: "Credentialing Tracking",
  },
  {
    index: 11,
    label: "Privileging",
  },
  {
    index: 12,
    label: "Payer",
  },
];

export default employeeEditorTabs;
