import { IUserRecordModel } from "@helpers/interfaces/authentication/Authentication";
import { IServiceSiteModel } from "@helpers/interfaces/serviceSites";
import { IServSiteAccessibilitiesModel } from "@helpers/interfaces/serviceSites/Accessibilities";
import { IServSiteBillingContactModel } from "@helpers/interfaces/serviceSites/BillingContact";
import { IServSiteLanguagesModel } from "@helpers/interfaces/serviceSites/Languages";
import { IServSiteOfficeHoursModel } from "@helpers/interfaces/serviceSites/OfficeHours";
import { IServSiteOfficeManagerModel } from "@helpers/interfaces/serviceSites/OfficeManager";
import { IServSiteOpenPracticeStatusModel } from "@helpers/interfaces/serviceSites/OpenPracticeStatus";
import { IServSitePartnerAssociatesModel } from "@helpers/interfaces/serviceSites/PartnerAssociates";
import { IServSitePaymentRemittanceModel } from "@helpers/interfaces/serviceSites/PaymentRemittance";
import { IServSitePractitionerDetailsModel } from "@helpers/interfaces/serviceSites/PractitionerDetails";
import { IServSitePrimaryCredentialingContactModel } from "@helpers/interfaces/serviceSites/PrimaryCredentialingContact";
import { IServSiteServicesModel } from "@helpers/interfaces/serviceSites/Services";

export function setDefaultServiceSiteData(): IServiceSiteModel {
  const data = {
    locationId: 0,
    name: "",
    address: "",
    addressLine2: "",
    city: 0,
    cityName: "",
    directoryId: 0,
    assBillingAddress: "",
    state: 0,
    stateName: "",
    zip: "",
    defaultLocation: false,
    isActive: true,
    email: "",
    taxId: "",
    npi: "",
    officeManagerName: "",
    phone: "",
    fax: "",
    typeofServiceProvided: "",
    doYouHaveCLIACertificate: false,
    doYouHaveCLIAWaiver: false,
    certificateNumber: "",
    certificateExpirationDate: null,
    isBillingAddress: false,
    associatedBillingAddress: 0,
    correspondenceAddress: 0,
    isPhysicalSite: false,
    isVirtualsite: false,
    isAffiliatedInstitution: false,
    nickName: "",
    isLocationAvailableTimeFrame: false,
    locationAvailabilityEndDate: null,
    isSuppressInProviderDirectory: false,
    website: "",
    isHospital: false,
    county: 0,
  };

  return data;
}

export function setDefaultPaymentRemittance(): IServSitePaymentRemittanceModel {
  const data = {
    locationId: 0,
    electronicBillingCapabilities: false,
    billingDepartment: "",
    checkPayable: "",
    prCheck: false,
    lastName: "",
    firstName: "",
    mi: "",
    number: "",
    street: "",
    building: "",
    state: 0,
    city: 0,
    zipCode: "",
    telephone: "",
    fax: "",
    email: "",
  };

  return data;
}

export function setDefaultOpenPracticeStatus(): IServSiteOpenPracticeStatusModel {
  const data = {
    locationId: 0,
    anPatientsIntoThisPractice: false,
    aAllNewPatients: false,
    aepwChangeOfPayor: false,
    anMedicarePatients: false,
    anpwPhysicianReferral: false,
    anMedicaidPatients: false,
    information: "",
    ataPracticeLimitation: false,
    genderLimitation: "",
    minAge: 0,
    maxAge: 0,
    listOtherLimitation: "",
    isLocationCostSharing: false,
  };

  return data;
}

export function setDefaultOfficeManagerData(): IServSiteOfficeManagerModel {
  const data = {
    locationId: 0,
    lastName: "",
    firstName: "",
    mi: "",
    telephone: "",
    fax: "",
    email: "",
  };

  return data;
}

export function setDefaultLanguagesData(): IServSiteLanguagesModel {
  const data = {
    locationId: 0,
    nonEnglishLanguages: "",
    interpretersAvailable: false,
    interpretedLanguages: "",
  };

  return data;
}

export function setDefaultAccessibilitiesData(): IServSiteAccessibilitiesModel {
  const data = {
    accessibilitiesId: 0,
    locationId: 0,
    accessibilityRequirement: false,
    servicesfortheDisabled: false,
    publicTransportation: false,
    building: false,
    parking: false,
    restRoom: false,
    textTelephony: false,
    americanSignLanguage: false,
    mentalPhysicalImpairmentServices: false,
    bus: false,
    subway: false,
    regionalTrain: false,
    otherHandicappedAccess: "",
    otherDisabilityService: "",
    otherTransportationAccess: "",
  };

  return data;
}

export function setDefaultServicesData(): IServSiteServicesModel {
  const data = {
    servicesId: 0,
    locationId: 0,
    laboratoryService: false,
    certifyingProgram: "",
    radiologyService: false,
    certificationType: "",
    ekgs: false,
    allergyInjection: false,
    allergySkinTesting: false,
    routineOfficeGynecology: false,
    drawingBlood: false,
    ageAppropriateImmunization: false,
    flexibleSigmoidoscopy: false,
    tympanometryAudiometryScreening: false,
    ashthmaTreatment: false,
    osteopathicManipulation: false,
    hydrationTreatement: false,
    cardiacStressTest: false,
    pulmonaryFunctionTesting: false,
    physicalTherapy: false,
    careOfMinorLacerations: false,
    anesthesiaAdministered: false,
    classCategory: "",
    lastName: "",
    firstName: "",
    soloPractice: false,
    singleSpecailtyGroup: false,
    multiSpecailtyGroup: false,
    additionalOfficeProcedure: "",
  };

  return data;
}

export function setDefaultPractitionerDetail(): IServSitePractitionerDetailsModel {
  const data = {
    practitionersId: 0,
    locationId: 0,
    midlevelPractitioners: false,
    lastName: "",
    firstName: "",
    mi: "",
    type: "",
    licenceNumber: "",
    state: "",
  };

  return data;
}

export function setDefaultPartnerAssociatesData(): IServSitePartnerAssociatesModel {
  const data = {
    partnerAssociateId: 0,
    locationId: 0,
    lastName: "",
    firstName: "",
    mi: "",
    spaciltyCode: "",
    providerType: "",
    specialtyName: "",
    providerTypeName: "",
    coveringColleague: false,
  };

  return data;
}

export function setDefaultOfficeHoursData(): IServSiteOfficeHoursModel {
  const data = {
    officeHoursId: 0,
    locationId: 0,
    mondayStartTime: "",
    mondayStartTimeStamp: "AM",
    mondayEndTime: "",
    mondayEndTimeStamp: "PM",
    tuesdayStartTime: "",
    tuesdayStartTimeStamp: "AM",
    tuesdayEndTime: "",
    tuesdayEndTimeStamp: "PM",
    wednesdayStartTime: "",
    wednesdayStartTimeStamp: "AM",
    wednesdayEndTime: "",
    wednesdayEndTimeStamp: "PM",
    thursdayStartTime: "",
    thursdayStartTimeStamp: "AM",
    thursdayEndTime: "",
    thursdayEndTimeStamp: "PM",
    fridayStartTime: "",
    fridayStartTimeStamp: "AM",
    fridayEndTime: "",
    fridayEndTimeStamp: "PM",
    saturdayStartTime: "",
    saturdayStartTimeStamp: "AM",
    saturdayEndTime: "",
    saturdayEndTimeStamp: "PM",
    sundayStartTime: "",
    sundayStartTimeStamp: "AM",
    sundayEndTime: "",
    sundayEndTimeStamp: "PM",
    phoneCoverage: false,
    answeringService: false,
    voiceMailAnswering: false,
    voiceMailInstruction: false,
    backOfficeTelephone: "",
  };

  return data;
}

export function setDefaultBillingContactData(): IServSiteBillingContactModel {
  const data = {
    billingContactId: 0,
    locationId: 0,
    bcCheck: false,
    lastName: "",
    firstName: "",
    mi: "",
    number: "",
    street: "",
    building: "",
    state: 0,
    city: 0,
    zipCode: "",
    telephone: "",
    fax: "",
    email: "",
    additionalContactId: 0,
  };

  return data;
}

export function setDefaultPrimaryCredentialingContact(): IServSitePrimaryCredentialingContactModel {
  const data = {
    primaryCredentialingId: 0,
    locationId: 0,
    pccCheck: false,
    lastName: "",
    firstName: "",
    mi: "",
    number: "",
    street: "",
    building: "",
    state: 0,
    city: 0,
    zip: "",
    telephone: "",
    fax: "",
    emailAdress: "",
    additionalContactId: 0,
  };

  return data;
}
